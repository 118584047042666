import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LogoFont } from '../common';
import RouteNames from 'app/RouteNames';
import { previousIco } from 'containers/Assets/images';
import { NextIconImageButton } from 'components/HomePage';
import { slide as Menu } from 'react-burger-menu';
import Hamburger from 'hamburger-react';

const menuList = [
  {
    label: 'Home',
    name: 'home',
    wrapperClass: 'border-bottom',
    linkTo: RouteNames.homePage.path,
  },
  {
    label: 'Best Card',
    name: 'Estimate Rewards',
    wrapperClass: 'border-bottom',
    redirectPath: {
      pathname: RouteNames.homePage.path,
      state: { type: 'Estimate Rewards' },
    },
  },
  {
    label: 'Missed Rewards',
    name: 'Missed',
    wrapperClass: 'border-bottom',
    redirectPath: {
      pathname: RouteNames.homePage.path,
      state: { type: 'Missed' },
    },
  },
  {
    label: 'Advice',
    name: 'Personalized',
    wrapperClass: 'border-bottom',
    mainPath: RouteNames.homePage.path,
    redirectPath: {
      pathname: RouteNames.homePage.path,
      state: { type: 'Personalized' },
    },
  },
  {
    label: 'Earned Rewards',
    name: 'Earned',
    wrapperClass: 'border-bottom',
    mainPath: RouteNames.homePage.path,
    redirectPath: {
      pathname: RouteNames.homePage.path,
      state: { type: 'Earned' },
    },
  },
  {
    label: 'My Account',
    name: 'myAccount',
    wrapperClass: 'border-bottom',
    linkTo: RouteNames.myProfile.path,
  },
  {
    label: 'Refer a Friend',
    name: 'referAFriend',
    wrapperClass: 'mobile-border-bottom',
    linkTo: RouteNames.myProfile.subPath.refer,
  },
  {
    label: 'Sign Out',
    name: 'signOut',
    wrapperClass: 'sign-out',
    isNoImageNext: true,
  },
];
const menuListMobile = [
  {
    label: 'Home',
    name: 'home',
    wrapperClass: 'border-bottom',
    linkTo: RouteNames.homePage.path,
  },
  {
    label: 'Missed Rewards',
    name: 'Missed',
    wrapperClass: 'border-bottom',
    redirectPath: {
      pathname: RouteNames.homePage.path,
      state: { type: 'Missed' },
    },
  },
  {
    label: 'Earned Rewards',
    name: 'Earned',
    wrapperClass: 'border-bottom',
    mainPath: RouteNames.homePage.path,
    redirectPath: {
      pathname: RouteNames.homePage.path,
      state: { type: 'Earned' },
    },
  },
  {
    label: 'Refer a Friend',
    name: 'referAFriend',
    wrapperClass: 'mobile-border-bottom',
    linkTo: RouteNames.myProfile.subPath.refer,
  },
  {
    label: 'Best Card',
    name: 'Estimate Rewards',
    wrapperClass: 'border-bottom',
    redirectPath: {
      pathname: RouteNames.homePage.path,
      state: { type: 'Estimate Rewards' },
    },
  },
  {
    label: 'Advice',
    name: 'Personalized',
    wrapperClass: 'border-bottom',
    mainPath: RouteNames.homePage.path,
    redirectPath: {
      pathname: RouteNames.homePage.path,
      state: { type: 'Personalized' },
    },
  },
  {
    label: 'My Account',
    name: 'myAccount',
    wrapperClass: 'border-bottom',
    linkTo: RouteNames.myProfile.path,
  },

  {
    label: 'Sign Out',
    name: 'signOut',
    wrapperClass: 'sign-out',
    isNoImageNext: true,
  },
];

export function HomeNavbar(props) {
  const { wrapperClass, onGoHome, onClickItemHome, history } = props;
  const [isOpen, setOpen] = useState(false);
  const historyDefault = useHistory();

  const onClickMenuItem = (type, item) => {
    setOpen(false);
    if (item.name === 'signOut') {
      // historyDefault.push('/landing');
      window.location.replace('https://www.uthrive.club/');
      localStorage.clear();
      return;
    }
    if (onClickItemHome) {
      onClickItemHome({ type });
    }
    if (item.linkTo) {
      history?.push(item.linkTo);
    }
    if (item?.redirectPath?.pathname !== window.location.pathname) {
      history?.push(item?.redirectPath);
    }
  };
  const onClickMenuDefault = () => {
    historyDefault.push('/');
  };

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light login-nav nav-group p-0 header ${
        wrapperClass || ''
      } ${isOpen ? 'navbar-open' : ''}`}
    >
      <div className="container-fluid container-navbar header-page">
        <div className="logo-navbar cursor">
          <LogoFont
            onClick={onGoHome ? onGoHome : onClickMenuDefault}
            wrapperClass="logo-header cursor"
          />
        </div>
        <div className={isOpen ? 'custom-hamburger' : ''}>
          <Hamburger
            toggled={isOpen}
            toggle={() => setOpen(!isOpen)}
            direction="right"
            size={30}
          />
          <Menu
            right
            isOpen={isOpen}
            onOpen={() => {}}
            onToggle={() => {}}
            disableOverlayClick
          >
            <div className="text-center header-back-grp-menu custom-btn-menu-profile">
              <button
                className="btn btn-select float-left acitve btn-back-menu-group"
                onClick={() => setOpen(!isOpen)}
              >
                <img
                  src={previousIco}
                  alt="img-previous"
                  className="btn-back-menu"
                />
                <span className="back-text">Back</span>
              </button>
              <span className="title-invite center-menu-title">Menu</span>
            </div>
            <div className="col-md-8 m-atuo row menu-gpr list-desktop">
              {menuList.map(val => (
                <div className="col-md-6" key={val.name}>
                  <div
                    className={`menu-item d-flex ${val.wrapperClass || ''}`}
                    onClick={() => onClickMenuItem(val.name, val)}
                  >
                    {val.label}
                    {!val.isNoImageNext && (
                      <NextIconImageButton wrapperClass="next-ico-menu-item" />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-8 m-atuo row menu-gpr list-mobile">
              {menuListMobile.map(val => (
                <div className="col-md-6" key={val.name}>
                  <div
                    className={`menu-item d-flex ${val.wrapperClass || ''}`}
                    onClick={() => onClickMenuItem(val.name, val)}
                  >
                    {val.label}
                    {!val.isNoImageNext && (
                      <NextIconImageButton wrapperClass="next-ico-menu-item" />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Menu>
        </div>
      </div>
    </nav>
  );
}
