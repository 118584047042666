import React from 'react';

export default function SwitchCustom(props) {
  return (
    <div className="custom-switch">
      <input {...props} type="checkbox" hidden={true} id="witch-notification" />
      <label className="switch" htmlFor="witch-notification" />
    </div>
  );
}
