import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { logType } from 'types/logTypes';
import { loggerHelper } from 'utils/utils';

import { previousIco, nextIco } from 'containers/Assets/images';
import RouteNames from '../../app/RouteNames';
const menuCategories = [
  {
    name: 'Customer Care',
    link: RouteNames.myProfile.subPath.customerCare,
    onclick: () => {
      loggerHelper(logType.clicked_helplegal_list_customercare);
    },
  },
  {
    name: 'Disclaimers',
    link: RouteNames.myProfile.subPath.acknowledgement,
    onclick: () => {
      loggerHelper(logType.clicked_helplegal_list_acknowledgement);
    },
  },
  {
    name: 'Terms & Conditions',
    link: RouteNames.myProfile.subPath.termOfUse,
    onclick: () => {
      loggerHelper(logType.clicked_helplegal_list_termsofuse);
    },
  },
  {
    name: 'Privacy',
    link: RouteNames.myProfile.subPath.privacy,
    onclick: () => {
      loggerHelper(logType.clicked_helplegal_list_privacy);
    },
  },
];

export default function MenuProfile(props: any) {
  const history = useHistory();

  useEffect(() => {
    loggerHelper(logType.page_helplegal_load);
  }, []);

  return (
    <div className="menu-profile-wrapper help-wrapper">
      <div className="text-center header-back-grp">
        <button
          type="button"
          className={`"btn btn-select float-left pl-0`}
          onClick={() => {
            history.goBack();
            loggerHelper(logType.page_helplegal_exit);
          }}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">Help & Legal</span>
      </div>
      <div className="container-menu-categories row ml-0 mr-0">
        <div className="col-md-10 row content-menu-categories ml-auto mr-auto">
          <div className="col-md-6 box-categories box-left">
            <p className="text-box-section">Help</p>
            {menuCategories.slice(0, 1).map((val, index) => (
              <Link to={val.link} key={index} className="category-item">
                <p className="mb-0 category-menu-name">{val.name}</p>
                <img
                  src={nextIco}
                  alt="arrow-right-icon"
                  className="arrow-right-icon"
                />
              </Link>
            ))}
          </div>
          <div className="col-md-6 box-categories box-right margin-bottom-0">
            <p className="text-box-section">Legal</p>
            {menuCategories.slice(1).map((val, index) => (
              <Link to={val.link} key={index} className="category-item">
                <p className="mb-0 category-menu-name">{val.name}</p>
                <img
                  src={nextIco}
                  alt="arrow-right-icon"
                  className="arrow-right-icon"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
