import axios from 'axios';
import { Auth } from 'aws-amplify';
import { setDefaultHeaders } from './Axios';
import { toast } from 'react-toastify';

// Add token, refresh token in header to call api
axios.interceptors.request.use(
  async config => {
    // Add configurations here
    let token = '';
    let session: any = await Auth.currentSession()
      .then(session => {
        return session;
      })
      .catch(error => console.log(error));

    if (session) {
      const idTokenExpire = session.getIdToken().getExpiration();
      const currentTimeSeconds = Math.round(+new Date() / 1000);
      if (idTokenExpire < currentTimeSeconds) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        session = cognitoUser.refreshSession(
          session.refreshToken,
          (err, session) => {
            if (session) return session;
            if (err) {
              console.log('err', err);
              return null;
            }
          },
        );
        const accessToken = session?.accessToken || null;
        token = accessToken?.jwtToken || null;
      } else {
        const accessToken = session.accessToken;
        token = accessToken?.jwtToken;
      }
    }
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
      setDefaultHeaders({ Authorization: `Bearer ${token}` });
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== '/landing'
    ) {
      localStorage.clear();
      window.location.replace('https://www.uthrive.club/');
      return Promise.reject(error);
    } else {
      const message = JSON.parse(error?.response?.data?.message);
      toast.error(
        (message?.message || error?.response?.data?.message) ??
          'Something failed',
        {
          toastId: 'request-toast-id',
        },
      );
      return Promise.reject(error);
    }
  },
);

export * from './authencations';
export * from './common';
export * from './rewards';
export * from './myAccount';
