import { getUserRegister, getUserCognitoVerified } from 'utils/localStorages';
import { cloneDeep, uniq } from 'lodash';
import numeral from 'numeral';
import amplitude from 'amplitude-js';
import { toast } from 'react-toastify';

const ampInstance = amplitude.getInstance();
ampInstance.init(`${process.env.REACT_APP_AMPLITUDE_KEY}`, 'guest');
let isSetUserIdAmplitude = false;

export const validatePhoneNumber = number => {
  const numberConvert = number.replace(/-/gi, '');
  const reg = /^[0-9]{1,10}$/;
  let checking = reg.test(numberConvert);
  if (numberConvert && numberConvert.replace(/_/gi, '').length === 10) {
    const numberSplit = uniq(numberConvert.replace(/_/gi, '').split(''));
    checking = !!(numberSplit && numberSplit.length > 1);
  }
  if (checking) {
    return false;
  }
  return true;
};

export const validateEmail = email => {
  const regex = /^([\w.+-])+@(((([a-zA-Z])+).)+)[a-zA-Z][a-zA-Z]+$/i;
  let valid = !regex.test(String(email).toLowerCase());
  return valid;
};

export const validateSSNNum = number => {
  const numberConvert = number.replace(/[*]/g, '').replace(/[_]/g, '');
  if (numberConvert.length !== 4) return false;
  if (numberConvert && numberConvert.length === 4) {
    const numberSplit = uniq(numberConvert.replace(/[_]/g, '').split(''));
    return numberSplit && numberSplit.length > 1 ? false : true;
  }
};

const formatStringUrl = (...args) => {
  let i = 1;
  const str = args[0];
  return str.replace(/:id/gi, () => args[i++]); // eslint-disable-line
};

export const prettifyPath = (path, ...args) => {
  return formatStringUrl(path, ...args);
};

export const checkSignUpStepByShadowStatus = () => {
  const user = getUserRegister();
  const verifyUserInfo = getUserCognitoVerified();
  if (user?.shadow_status) {
    switch (user.shadow_status) {
      case 'USER_CONFIRM_REQD':
        return 2;
      case 'VERIFY_IDENTITY_REQD':
        if (verifyUserInfo) return 3;
        return 2;
      case 'DOB_SSN_REQD':
        return 3;
      case 'DOB_REQD':
        return 3;
      case 'SSN_REQD':
        return 3;
    }
  }
  return 0;
};

export const checkUserVerified = () => {
  const userCognito = getUserCognitoVerified() || null;
  const userRegister = getUserRegister() || null;
  const token = userCognito?.signInUserSession?.accessToken?.jwtToken || null;
  const isLinkedPlaid = !!userRegister?.hasLinkedPlaid;
  const isVerified = !!userRegister?.accountVerification?.accountVerification;
  if (
    (userRegister && userRegister?.shadow_status !== 'NO') ||
    !isLinkedPlaid ||
    !isVerified
  )
    return false;
  return !!token;
};

export const checkTokenExist = () => {
  const userCognito = getUserCognitoVerified() || null;
  const token = userCognito?.signInUserSession?.accessToken?.jwtToken || null;
  return !!token;
};

export const formatMoneyValue = (value, isNoNeedPrefix = false) => {
  if (typeof value !== 'number') return 0;
  if (value % 1 === 0 || isNoNeedPrefix) {
    return numeral(value || '0').format('0,0');
  }
  return numeral(value || '0').format('0,0.00');
};

export const initUserIdAmplitude = () => {
  const userRegister = getUserRegister() || null;

  if (
    !isSetUserIdAmplitude &&
    (userRegister?.userId || userRegister?.user?.id)
  ) {
    amplitude
      .getInstance()
      .setUserId(userRegister?.userId || userRegister?.user?.id);
  }
};

export const loggerHelper = event => {
  const newEvent = cloneDeep(event);
  initUserIdAmplitude();
  if (newEvent?.referral_code) {
    return amplitude.getInstance().logEvent(newEvent.event, {
      subevent: newEvent.subevent,
      referral_code: newEvent.referral_code,
    });
  }
  return amplitude
    .getInstance()
    .logEvent(newEvent.event, { subevent: newEvent.subevent });
};

export const scrollToTopElm = id => {
  const node = document.querySelector(id);
  if (node)
    node.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
};

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const getAddressHelper = data => {
  const addressDetails = {
    streetAddress: '',
    streetNumber: '',
    apartmentAddress: '',
    city: '',
    state: '',
    zipCode: '',
  };
  if (!data) return addressDetails;
  addressDetails.streetAddress =
    (data?.address_components.find(c => c.types.includes('route')) || {})
      .long_name || '';
  addressDetails.streetNumber =
    (
      data?.address_components.find(c => c.types.includes('street_number')) ||
      {}
    ).long_name || '';
  // addressDetails.apartmentAddress =
  //   (data?.address_components.find(c => c.types.includes('country')) || {})
  //     .long_name || '';

  addressDetails.city =
    (data?.address_components.find(c => c.types.includes('locality')) || {})
      .long_name || '';

  addressDetails.state =
    (
      data?.address_components.find(c =>
        c.types.includes('administrative_area_level_1'),
      ) || {}
    ).short_name || '';

  addressDetails.zipCode =
    (data?.address_components.find(c => c.types.includes('postal_code')) || {})
      .long_name || '';

  return addressDetails;
};

export const setCaretPosition = id => {
  const input = document.getElementById(id) as any;
  // Modern browsers
  if (input && input.setSelectionRange) {
    input.focus();
    // input.setSelectionRange(
    //   input?.value?.length || 0,
    //   input?.value?.length || 0,
    // );

    // IE8 and below
  } else if (input && input.createTextRange) {
    const range = input.createTextRange();
    range.collapse(true);
    range.moveEnd('character', input.value.length);
    range.moveStart('character', input.value.length);
    range.select();
  }
};

export const secretEmail = value => {
  if (!value) return null;
  const newChart = value;
  const indexChart = newChart.indexOf('@');
  const firtsChart = newChart.substring(0, indexChart - 6);
  const lastString = newChart.substring(indexChart, newChart.length);
  return `${firtsChart}******${lastString}`;
};

export const secretPhone = value => {
  if (!value) return null;
  const last4Number = value.slice(value.length - 4);
  return '***-***-' + last4Number;
};

export const showSignUpBonus = ({ type, value }) => {
  const newType = type?.toLowerCase();
  if (
    newType === 'cashback' ||
    newType === 'giftcard' ||
    newType === 'statementcredit'
  ) {
    if (newType === 'cashback') {
      return `$ ${formatMoneyValue(value, true)} Cash back`;
    }
    if (newType === 'giftcard') {
      return `$ ${formatMoneyValue(value, true)} Gift card`;
    }
    if (newType === 'statementcredit') {
      return `$ ${formatMoneyValue(value, true)} Statement credit`;
    }
  }
  return `${formatMoneyValue(value, true)} Points`;
};

export function getCurrentLocation(callBack) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(callBack, showError);
  } else {
    toast.error('Geolocation is not supported by this browser.');
  }
}

function showError(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      toast.error(
        'User denied the request for Geolocation. Please setting your Location service!',
      );
      break;
    case error.POSITION_UNAVAILABLE:
      toast.error('Location information is unavailable.');
      break;
    case error.TIMEOUT:
      toast.error('The request to get user location timed out.');
      break;
    case error.UNKNOWN_ERROR:
      toast.error('An unknown error occurred.');
      break;
  }
}
export function getMinuteFromTwoDate(currentDay, previousDay) {
  const diffMs = currentDay - previousDay;
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  return diffMins;
}

export function getHoursFromTwoDate(currentDay, previousDay) {
  const diffMs = currentDay - previousDay;
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000 / 60); // minutes
  return diffMins;
}

export function getStatusCard(status) {
  switch (status) {
    case 'VALIDATED':
      return '';
    case 'PENDING':
      return 'Pending';
    case 'NOT_VALIDATED':
      return 'Validate';
    default:
      return '';
  }
}
