import React, { useState } from 'react';
import { NameCardInput } from './Custominput';

export function EnterNameCardEditAgain({ cardValidate, hanldeConfirm }) {
  const [cardName, setCardName] = useState(
    cardValidate?.otherCardUserPrompt || '',
  );
  const [validate, setValidate] = useState(false);
  const onChange = cardName => {
    setCardName(cardName);
  };
  const onConfirm = () => {
    if (cardName) {
      hanldeConfirm({
        otherCard: cardName,
        isConfirm: 'N',
        cb: () => {
          setCardName('');
        },
      });
    }
  };
  const cardNameValidate = `${
    cardValidate?.institutionShortName || cardValidate?.cardShortName || ''
  } - ${cardValidate?.accountMask}`;
  return (
    <div className="card-validation">
      <div className="card-validation__title">{cardNameValidate}</div>
      <div className="card-validation__message">
        Please enter the name of above card below to link it successfully:
      </div>
      <div className="card-validation__input">
        <NameCardInput
          reset={false}
          validate={validate}
          onChange={onChange}
          setValidate={setValidate}
          value={cardValidate?.otherCardUserPrompt}
        />
      </div>
      <div
        className={`card-validation__btn ${
          cardName.length < 4 ? 'disabled' : ''
        }`}
        onClick={onConfirm}
      >
        Confirm
      </div>
    </div>
  );
}
