import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

export const ModalItems = props => {
  const {
    toggle,
    className,
    isOpen,
    children,
    isSupportClose,
    modalClass,
    subClass,
    onClose,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={`${className || ''}`}
      centered
    >
      <div className={`p-4 ${subClass || 'modal-view'} ${modalClass || ''}`}>
        {isSupportClose && (
          <div className="text-right">
            <button
              className="btn-close float-right m-0"
              onClick={toggle || onClose}
            >
              ×
            </button>
          </div>
        )}
        <ModalBody className="modal-item">{children}</ModalBody>
      </div>
    </Modal>
  );
};
