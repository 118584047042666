import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMyAccountFormSlice } from './slice';
import { previousIco, iconSendSuccess } from 'containers/Assets/images';
import RouteNames from 'app/RouteNames';

export default function MenuProfile() {
  const history = useHistory();
  const { actions } = useMyAccountFormSlice();
  const dispatch = useDispatch();

  const [isShowAddCard, setIsNewAddCard] = useState(true);
  const [messages, setMessages] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const handlePostContact = () => {
    dispatch(
      actions.postContactUs({
        caseDescription: messages,
        cb: () => setIsComplete(true),
      }),
    );
  };

  return (
    <div className="profile-link-account-wrapper contact-us-wrapper">
      <div className="text-center header-back-grp">
        <button
          type="button"
          className={`"btn btn-select float-left pl-0`}
          onClick={() => history.goBack()}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text cursor">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">Contact Us</span>

        {isShowAddCard && !isComplete && (
          <div className="col-md-10 p-0 m-auto">
            <div className="col-md-11 p-0 m-auto">
              <div className="link-more-account-suggest border-radius mr-0">
                <div className="txt-link-more">
                  <span className="txt-question-add">
                    Want instant answers to your questions?
                  </span>
                  <span className="txt-link-button">
                    <button
                      onClick={() =>
                        history.push(RouteNames.myProfile.subPath.customerCare)
                      }
                      className="link-more-account-question-btn ml-2 link-more-yes"
                    >
                      Check Customer Care
                    </button>
                    <button
                      className="link-more-account-question-btn ml-2"
                      onClick={() => setIsNewAddCard(false)}
                    >
                      No
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container-contact row ml-0 mr-0">
          <div className="col-md-10 p-0 row  ml-auto mr-auto">
            {isComplete ? (
              <div className="col-md-11 ml-auto mr-auto content-menu-categories">
                <div className="text-thank-you">
                  Thank you, we received your message.
                </div>
                <div className="box-img-success-message">
                  <img src={iconSendSuccess} alt="success-message" />
                </div>
                <div className="text-des-thank">
                  Thank you for reaching out. We will get back to you as soon as
                  we can.
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => setIsComplete(!isComplete)}
                    className="bnt-send"
                  >
                    Back
                  </button>
                </div>
              </div>
            ) : (
              <div className="col-md-11 ml-auto mr-auto content-menu-categories">
                <div className="box-text-top ">
                  Or message us through the form and we will respond as soon as
                  we can.
                </div>
                <div className="text-message">Message</div>
                <div className="container-text">
                  <textarea
                    className="text-contact"
                    maxLength={400}
                    value={messages}
                    onChange={event => setMessages(event.target.value)}
                  />
                  <p className="text-count">{`${messages?.length}/4000 characters`}</p>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={handlePostContact}
                    disabled={!!!messages?.length}
                    className="bnt-send"
                  >
                    Send
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
