import * as React from 'react';

export function AfterEnterNameCard({ listCards }) {
  return (
    <div className="card-validation">
      <div className="card-validation__title">Card Validation</div>
      <div className="card-validation__message">
        Based on your input, the following card is being validated by Uthrive to
        link successfully. There is no action needed from you.
      </div>
      <div className="card-error">
        <ul className="card-validation__list">
          <li>
            <div className="card-name">Chase Credit Card - 1475</div>
            <div className="card-image border" />
            <div className="card-btn">Pending</div>
          </li>
        </ul>
        <div className="card-validation__btn">Link more accounts</div>
        <div className="card-validation__btn-text">Take me home</div>
      </div>
    </div>
  );
}
