import React, { useState, createContext, useContext } from 'react';
import { deleteAccount, getOTP } from 'api/myAccount';
import { toast } from 'react-toastify';
import { loggerHelper } from 'utils/utils';
// import { getUserRegister } from 'utils/localStorages';
import { logType } from 'types/logTypes';

const DeleteAccountContext = createContext<any>(null);
function DeleteAccountProvider({ children }: any) {
  const [messages, setMessages] = useState('');
  const [pageType, setPageType] = useState(1);
  const [timmerOtp, setTimerOtp] = useState({
    timmer: Date.now(),
    key: Date.now().toString(),
  });
  const [loading, setLoading] = useState(false);
  const [messageErrorOTP, setMessageErrorOTP] = useState('');
  const [invalidCounter, setInvalidCounter] = useState(0);
  // const userLogin = getUserRegister();

  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      await getOTP();
      setPageType(pageType + 1);
      setTimerOtp({
        timmer: Date.now() + 59000,
        key: Date.now().toString(),
      });
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data ?? 'Something failed', {
        toastId: 'request-toast-id',
      });
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    setTimerOtp({ timmer: Date.now() + 59000, key: Date.now().toString() });
    setMessageErrorOTP('');
    try {
      await getOTP();
      loggerHelper(logType.clicked_deleteaccount_otp_result_success);
    } catch (error) {
      loggerHelper(logType.clicked_deleteaccount_otp_result_fail);
    }
  };

  const handleCheckOTP = async ({ value }) => {
    if (value?.length === 6) {
      try {
        setLoading(true);
        await deleteAccount({
          feedback: messages,
          OTP: value,
          // type: userLogin?.phone_number ? 'PHONE' : 'EMAIL',
        });
        setLoading(false);
        setPageType(pageType + 1);
        if (pageType + 1 === 4) {
          setTimeout(() => {
            localStorage.clear();
            window.location.replace('https://www.uthrive.club/');
          }, 5000);
        }
      } catch (error: any) {
        setLoading(false);
        if (invalidCounter === 3) {
          setMessageErrorOTP(
            `Limit reached!Please come back in an hour to 'Resend 'and enter the code.`,
          );
          return;
        } else {
          setMessageErrorOTP(
            `Invalid code!You have ${
              3 - invalidCounter
            } more tries left. Please check again the verification code we sent and re-enter.`,
          );
          setInvalidCounter(invalidCounter + 1);
        }
      }
    }
  };

  return (
    <DeleteAccountContext.Provider
      value={{
        messages,
        setMessages,
        handleDeleteAccount,
        pageType,
        setPageType,
        resendOtp,
        handleCheckOTP,
        messageErrorOTP,
        setMessageErrorOTP,
        timmerOtp,
        setTimerOtp,
        loading,
        invalidCounter,
      }}
    >
      {children}
    </DeleteAccountContext.Provider>
  );
}

function useDeleteAccount() {
  const loading = useContext(DeleteAccountContext);
  if (!loading) {
    throw new Error(
      `useDeleteAccount must be used within a DeleteAccountProvider`,
    );
  }
  return loading;
}

export { DeleteAccountProvider, DeleteAccountContext, useDeleteAccount };
