import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

export const ModalInfo = props => {
  const {
    toggle,
    className,
    isOpen,
    isSupportClose,
    modalClass,
    title,
    content,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={`${className || 'modal-info'}`}
    >
      <div className={`p-4 ${modalClass || ''}`}>
        {isSupportClose && (
          <div className="text-right">
            <button className="btn-close float-right" onClick={toggle}>
              ×
            </button>
          </div>
        )}
        <ModalBody className="modal-info">
          <div>
            <div className="title-info-modal">{title || ''}</div>
            <div className="content-info-modal">{content || ''}</div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};
