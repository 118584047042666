import * as React from 'react';
import { logoIco, whiteLogoIco } from 'containers/Assets/images';

export const LogoFont = ({
  wrapperClass = '',
  isWhiteLogo = false,
  onClick,
  loggerHelper,
}: any) => {
  return (
    <div
      className={`logo-group ${wrapperClass || ''}`}
      onClick={() => {
        onClick ? onClick() : window.open('https://www.uthrive.club/', '_self');
        loggerHelper && loggerHelper();
      }}
    >
      <img
        src={isWhiteLogo ? whiteLogoIco : logoIco}
        alt="logo-landing"
        className="logo img-logo"
      />
    </div>
  );
};
