import React, { useState, useEffect } from 'react';
import searchIcon from 'containers/Assets/images/search.png';
import closeIcon from 'containers/Assets/images/close.png';

function SearchInput({
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onClick = () => {},
  onCloseSearch = () => {},
  renderResult,
  data = [],
  inputValue,
  placeholder,
  isSearching,
  ...props
}: any) {
  const [focusing, setFocusing] = useState(false);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    const searchInputEle: any = document.querySelector('#search-input');
    searchInputEle?.addEventListener('focus', () => {
      setFocusing(true);
      setShowResults(true);
      onFocus();
    });
    searchInputEle?.addEventListener('blur', () => {
      setTimeout(() => {
        setFocusing(false);
      }, 500);
      onBlur();
    });
    document.addEventListener('click', e => {
      const searchResultsEle: any = document.querySelector(
        '#search-result-container',
      );
      const containResult = searchResultsEle?.contains(e.target);
      const containInput = searchInputEle?.contains(e.target);
      if (!containResult && !containInput) setShowResults(false);
    });

    document.addEventListener('keypress', e => {
      const searchResultsEle: any = document.querySelector(
        '#search-result-container',
      );
      const containResult = searchResultsEle?.contains(e.target);
      const containInput = searchInputEle?.contains(e.target);
      if (!containResult && !containInput) setShowResults(false);
      if (e.key === 'Enter') {
        if (data && data.length) {
          onClick(data[0]);
          setShowResults(false);
          onBlur();
          searchInputEle?.blur();
        }
      }
    });
  }, [data]);

  useEffect(() => {
    if (focusing) {
      onChange({ target: { value: inputValue } });
    }
  }, [focusing]);

  const handleClickCLose = () => {
    onCloseSearch();
    setShowResults(false);
  };
  return (
    <>
      <div className="search-input-custom">
        <img
          src={searchIcon}
          alt="search-icon"
          width="25px"
          height="24px"
          className="search-input__icon"
        />
        <input
          id="search-input"
          placeholder={placeholder || 'Search...'}
          value={inputValue}
          onChange={onChange}
        />
        <div style={{ width: '10px' }}>
          {(!!inputValue || focusing) && (
            <img
              className="close-search"
              src={closeIcon}
              alt="close-icon"
              width="10px"
              height="10px"
              onClick={handleClickCLose}
            />
          )}
        </div>
      </div>
      {showResults && data?.length && isSearching ? (
        <div className="search-card-container" id="search-result-container">
          {data.map((result, index) => (
            <div
              className="search-card-item"
              onClick={() => {
                onClick(result);
                setShowResults(false);
              }}
              key={index}
            >
              {renderResult ? renderResult(result) : result}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}

export { SearchInput };
