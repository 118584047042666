import React, { useMemo } from 'react';
import { filter } from 'lodash';
import {
  // loggerHelper,
  getStatusCard,
} from 'utils/utils';
// import { logType } from 'types/logTypes';
import { iconCheckSuccess } from 'containers/Assets/images';

export function ListValidationCard({
  listCards,
  handleGoToStep,
  hanldeCalculateRewards,
  handleGetListCardsSelect,
}) {
  //count card not validate
  const countCardsNotValidated = useMemo(
    () =>
      filter(listCards, function (o) {
        return o.linkStatus === 'NOT_VALIDATED';
      }).length || 0,
    [listCards],
  );
  //count card validated
  const countCardsValidated = useMemo(
    () =>
      filter(listCards, function (o) {
        return o.linkStatus === 'VALIDATED';
      }).length || 0,
    [listCards],
  );
  //count card pending
  const countCardsPending = useMemo(
    () =>
      filter(listCards, function (o) {
        return o.linkStatus === 'PENDING';
      }).length || 0,
    [listCards],
  );

  return (
    <div className="card-validation">
      <div className="card-validation__title">Card Validation</div>
      <div className="card-validation__message">
        {countCardsPending === listCards.length
          ? 'Based on your input, the following card is being validated by Uthrive to link successfully. There is no action needed from you.'
          : !countCardsNotValidated
          ? 'Validation successful Your cards have been successfully validated. Click below to calculate rewards.'
          : 'We received incomplete information for below accounts, please validate to link successfully:'}
      </div>
      <ul className="card-validation__list">
        {(listCards || []).map(item => {
          const cardName = !!item?.mappedCard
            ? `${item.mappedCard} - ${item.accountMask}`
            : item?.linkStatus === 'PENDING'
            ? `${item?.otherCardUserPrompt || ''} - ${item.accountMask}`
            : `${item?.institutionShortName} ${item?.accountSubtype} - ${item?.accountMask}`;
          const cardImg = item?.cardImg || item?.cardImage || '';
          const cardStatus = getStatusCard(item?.linkStatus);
          return (
            <li key={cardName}>
              <div className="card-name">{cardName}</div>
              <div className="card-image border">
                {cardImg ? (
                  <img
                    src={cardImg}
                    alt="avatar card"
                    width="100%"
                    height="100%"
                  />
                ) : null}
              </div>
              <div
                className="card-btn"
                onClick={() => handleGetListCardsSelect(item, item?.linkStatus)}
              >
                {cardStatus ? (
                  cardStatus
                ) : (
                  <img
                    src={iconCheckSuccess}
                    alt="icon-checked"
                    className="icon-check"
                  />
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {!!listCards.length &&
        (listCards.length === countCardsPending &&
        listCards[0].linkStatus === 'PENDING' ? (
          <div
            className="card-validation__btn"
            onClick={() => handleGoToStep('home')}
          >
            Back to home
          </div>
        ) : (
          <div
            className={`card-validation__btn ${
              !countCardsValidated ? 'disabled' : ''
            }`}
            onClick={hanldeCalculateRewards}
          >
            Calculate rewards
          </div>
        ))}
    </div>
  );
}
