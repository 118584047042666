import { call, put, takeLatest } from 'redux-saga/effects';
import { myAccountFormActions as actions } from '.';
import {
  getLinkedAccount,
  validatePlaid,
  getLinkPlaid,
  getMyProfile,
  updateMyProfile,
  getNotificationPreference,
  updateNotificationPreference,
  postContactUs,
  getHelpQuestion,
  getReLinkPlaid,
  updateRelink,
  answerRewardPrompt,
  skipRewardPrompt,
  getRewardPrompt,
  mappedCardFailed,
  getPlaidByUser,
  getTokenPlaid,
  getLinkedAccountById,
  unlinkAccountPlaid,
  relinkAccountPlaid,
} from 'api';
import { setConnectPlaid } from 'utils/localStorages';

export function* getLinkPlaidSaga() {
  try {
    const response = yield call(getLinkPlaid);
    if (response?.data) {
      yield put(actions.getLinkPlaidSuccess(response.data));
    }
  } catch (error) {}
}

export function* getReLinkPlaidSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getReLinkPlaid, payload);
    if (response?.data) {
      yield put(actions.getLinkPlaidSuccess(response.data));
    }
  } catch (error) {}
}

export function* getLinkedAccountSaga() {
  try {
    const response = yield call(getLinkedAccount);
    if (response?.data) {
      yield put(actions.getLinkedAccountSuccess(response.data?.data || {}));
    }
  } catch (error) {}
}

export function* getMyProfileSaga() {
  try {
    const response = yield call(getMyProfile);
    if (response?.data) {
      yield put(actions.getProfileSuccess(response.data?.data || {}));
    }
  } catch (error) {}
}

export function* validatePlaidSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...rest } = payload || {};
  try {
    const response = yield call(validatePlaid, { payload: rest });
    if (response?.data?.status === 400) {
      if (cb) {
        cb({
          success: false,
          data: {
            message: response?.data?.response?.message,
            idError: response?.data?.response?.id,
          },
        });
      }

      return;
    }
    if (response?.data) {
      yield put(actions.validatePlaidSuccess(response.data));
      setConnectPlaid('true');
      if (cb) cb({ success: true });
    }
  } catch (error) {
    if (cb) cb({ success: false });
  }
}

export function* updateAccountSaga(actionPayload) {
  const {
    payload: { cb, ...rest },
  } = actionPayload;
  try {
    const response = yield call(updateMyProfile, rest);
    if (response?.data?.success) {
      if (cb) cb();
    }
  } catch (error) {}
}

export function* getNotificationsSaga() {
  try {
    const response = yield call(getNotificationPreference);
    if (response?.data) {
      yield put(actions.getNotificationSuccess(response.data?.data || {}));
    }
  } catch (error) {}
}

export function* updateNotificationSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(updateNotificationPreference, { payload });
    if (response?.data) {
      yield put(
        actions.updateNotificationSuccess(response.data?.data?.payload),
      );
    }
  } catch (error) {}
}

export function* postContactUsSaga(actionPayload) {
  const {
    payload: { cb, ...rest },
  } = actionPayload;
  try {
    const response = yield call(postContactUs, rest);
    if (response?.data?.success) {
      if (cb) cb();
    }
  } catch (error) {}
}

export function* getHelpQuestionSaga() {
  try {
    const response = yield call(getHelpQuestion);
    if (response?.data) {
      yield put(actions.getHelpQuestionSuccess(response.data?.data || {}));
    }
  } catch (error) {}
}

export function* updateReLinkPlaidSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...params } = payload || {};
  try {
    const response = yield call(updateRelink, params);
    if (response?.data) {
      if (cb) cb();
    }
  } catch (error) {}
}

export function* answerRewardPromptSaga(actionPayload) {
  const {
    payload: { cb, ...rest },
  } = actionPayload;
  try {
    const response = yield call(answerRewardPrompt, rest);
    if (response?.data?.success) {
      if (cb) cb({ success: true });
    }
  } catch (error) {}
}

export function* skipRewardPromptSaga(actionPayload) {
  const {
    payload: { cb, ...rest },
  } = actionPayload;
  try {
    const response = yield call(skipRewardPrompt, rest);
    if (response?.data?.success) {
      if (cb) cb({ success: true });
    }
  } catch (error) {}
}

export function* getRewardPromptSaga(actionPayload) {
  const {
    payload: { cb },
  } = actionPayload;
  try {
    const response = yield call(getRewardPrompt);
    if (response?.data?.data) {
      yield put(actions.getRewardPromptSuccess(response.data.data));
      if (cb) cb();
    }
  } catch (error) {}
}

export function* mappedFailedCardSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    yield call(mappedCardFailed, payload);
  } catch (error) {}
}

export function* getPlaidByUserSaga(actionPayload) {
  const { payload } = actionPayload;
  try {
    const response = yield call(getPlaidByUser);
    if (response?.data) {
      if (payload.cb) payload.cb({ success: true, data: response.data });
    } else {
      if (payload.cb) payload.cb({ success: false, data: null });
    }
  } catch (error) {
    if (payload.cb) payload.cb({ success: false, data: null });
  }
}

export function* getPlaidTokenByAccountSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...rest } = payload || {};
  try {
    const response = yield call(getTokenPlaid, rest);

    if (response?.data) {
      yield put(actions.getPlaidTokenByAccountSuccess(response?.data));
    }
    if (cb) cb();
  } catch (error) {}
}

export function* getLinkedAccountByIdSaga(actionPayload) {
  try {
    const {
      payload: { id, cb },
    } = actionPayload;
    const response = yield call(getLinkedAccountById, { id });
    if (response?.data) {
      yield put(actions.getLinkedAccountByIdSuccess(response.data?.data || {}));
    }
    if (cb) cb();
  } catch (error) {}
}

export function* confirmUnLinkPaidSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...rest } = payload || {};
  try {
    const response = yield call(unlinkAccountPlaid, { ...rest });
    const success = response.data.success || false;
    if (cb) cb(success);
  } catch (error) {
    if (cb) cb(false);
  }
}

export function* confirmReLinkPaidSaga(actionPayload) {
  const { payload } = actionPayload;
  const { cb, ...rest } = payload || {};
  try {
    const response = yield call(relinkAccountPlaid, { ...rest });
    const success = response.data.success || false;
    if (cb) cb(success);
  } catch (error) {
    if (cb) cb(false);
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* myAccountFormSaga() {
  yield takeLatest(actions.getLinkedAccount.type, getLinkedAccountSaga);
  yield takeLatest(actions.getReLinkPlaid.type, getReLinkPlaidSaga);
  yield takeLatest(actions.updateReLinkPlaid.type, updateReLinkPlaidSaga);
  yield takeLatest(actions.validatePlaid.type, validatePlaidSaga);
  yield takeLatest(actions.getLinkPlaid.type, getLinkPlaidSaga);
  yield takeLatest(actions.getProfile.type, getMyProfileSaga);
  yield takeLatest(actions.updateProfile.type, updateAccountSaga);
  yield takeLatest(actions.getNotification.type, getNotificationsSaga);
  yield takeLatest(actions.updateNotification.type, updateNotificationSaga);
  yield takeLatest(actions.postContactUs.type, postContactUsSaga);
  yield takeLatest(actions.getHelpQuestion.type, getHelpQuestionSaga);
  yield takeLatest(actions.skipRewardPrompt.type, skipRewardPromptSaga);
  yield takeLatest(actions.answerRewardPrompt.type, answerRewardPromptSaga);
  yield takeLatest(actions.getRewardPrompt.type, getRewardPromptSaga);
  yield takeLatest(actions.mappedFailedCard.type, mappedFailedCardSaga);
  yield takeLatest(actions.getPlaidLinkByUser.type, getPlaidByUserSaga);

  yield takeLatest(
    actions.getPlaidTokenByAccount.type,
    getPlaidTokenByAccountSaga,
  );
  yield takeLatest(actions.getLinkedAccountById.type, getLinkedAccountByIdSaga);
  yield takeLatest(actions.confirmUnlink.type, confirmUnLinkPaidSaga);
  yield takeLatest(actions.confirmRelink.type, confirmReLinkPaidSaga);
}
