import * as React from 'react';
import { SyncLoader } from 'react-spinners';
import Countdown from 'react-countdown';
import { LinkMoreAccountsButton } from 'components/common';
import { logType } from 'types/logTypes';
import { loggerHelper } from 'utils/utils';

let typeLog = [] as any;

export const LoaderPlaid = ({ isLoading = false, onOpenPlaid, ready }) => {
  if (!isLoading) {
    return <div />;
  }
  return (
    <div id="pre-loader" className="loader loader-wrapper">
      <div className="text-header">
        Please do not refresh or click the back button
      </div>
      <div className="group-loader">
        <SyncLoader loading size={20} color="#3333cc" />
        <Countdown
          precision={100}
          // intervalDelay={10000}
          date={Date.now() + 45000}
          renderer={({ seconds, completed, total }) => {
            if (seconds > 34) {
              if (typeLog.indexOf('analyzing') === -1) {
                typeLog.push('analyzing');
                loggerHelper(
                  logType.page_processing_info_analyzing_spendinghabits,
                );
              }
              return (
                <div className="mt-3 creating-plaid animate__animated animate__fadeInUp">
                  Analyzing your spending habits…
                </div>
              );
            }

            if (seconds > 23) {
              if (typeLog.indexOf('rewards') === -1) {
                typeLog.push('rewards');
                loggerHelper(logType.page_processing_info_updating_rewards);
              }

              return (
                <div className="mt-3 creating-plaid animate__animated animate__fadeInUp">
                  Updating your rewards information...
                </div>
              );
            }

            if (seconds > 12) {
              if (typeLog.indexOf('personalized') === -1) {
                typeLog.push('personalized');
                loggerHelper(
                  logType.page_processing_info_creating_personalized_advise,
                );
              }

              return (
                <div className="mt-3 creating-plaid animate__animated animate__fadeInUp">
                  Creating personalized advice for you...
                </div>
              );
            }

            if (typeLog.indexOf('almost') === -1) {
              typeLog.push('almost');
              loggerHelper(logType.page_processing_info_almostthere);
            }
            return (
              <div className="mt-3 creating-plaid animate__animated animate__fadeInUp">
                Almost there...
              </div>
            );
          }}
        />
        <div className="link-more-account-loader">
          <LinkMoreAccountsButton
            onOpenPlaid={() => {
              onOpenPlaid();
              loggerHelper(
                logType.clicked_plaid_process_button_link_moreaccount,
              );
            }}
            disabled={!ready}
          />
        </div>
      </div>
    </div>
  );
};
