import React, { useState } from 'react';
import Datetime from 'react-datetime';
import { fieldValidation } from 'containers/Assets/images';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const DateTimePicker = ({
  isValid,
  label,
  type,
  id,
  elm,
  wrapperClass,
  onChange,
  name,
  validText,
  isShowImgValide,
  value,
  dateFormat,
  initialValue,
  rightLink,
  onShowPopup,
  ...rest
}: any) => {
  const [date, setDate] = useState(null) as any;
  const idRandom = id || `${Math.random().toString().replace('.', '')}`;
  const handleChange = newDate => {
    const newDateFormat = moment(newDate).format(dateFormat || 'MM/DD/YYYY');
    setDate(new Date(newDate));
    onChange({ name, value: newDateFormat });
  };
  return (
    <div className={`form-group p-0 m-0 ${wrapperClass}`}>
      <div>
        <Datetime
          className={`col-md-12 m-0 p-0 ${isValid || ''}`}
          dateFormat={dateFormat || 'MM/DD/YYYY'}
          timeFormat={false}
          value={date || initialValue}
          onChange={handleChange}
          initialValue={
            initialValue
              ? moment(initialValue).format(dateFormat || 'MM/DD/YYYY')
              : null
          }
          {...rest}
        />
      </div>
      {isShowImgValide && (
        <img
          src={fieldValidation}
          className="validation-date-img"
          alt="img-validation"
          height="30px"
          width="30px"
        />
      )}
      {label && (
        <label className="small-font-size" htmlFor={idRandom}>
          {label || ''}
        </label>
      )}
      {rightLink && (
        <button
          className="btn-text no-border small-font-size p-0 cursor float-right info-link"
          onClick={onShowPopup}
        >
          {rightLink || ''}
        </button>
      )}
      {isValid && (
        <div className="small-font-size valid-text">{validText || ''}</div>
      )}
      {elm ? elm : ''}
    </div>
  );
};

export default DateTimePicker;
