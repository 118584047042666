import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';

export const initialState: any = {
  loading: false,
};

const slice = createSlice({
  name: 'progressReducer',
  initialState,
  reducers: {
    setLinkingOn(state) {
      state.loading = true;
    },
    setLinkingOff(state) {
      state.loading = false;
    },
  },
});

export const { actions: homeFormActions, reducer } = slice;

export const useProgressSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
