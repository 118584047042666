import React from 'react';

export const CheckGroup = ({ label, wrapperClass, ...rest }) => {
  return (
    <div className={`pretty ${wrapperClass || ''}`}>
      <input type="checkbox" {...rest} />
      <div className="state p-success">
        <i className="icon mdi mdi-check"></i>
        <label className="label-checkbox">{label}</label>
      </div>
    </div>
  );
};
