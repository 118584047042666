import React from 'react';
import { Progress } from 'reactstrap';

const ProgressBar = ({ value }) => {
  return (
    <div className="progress-bar-wrapper">
      <Progress
        className="bar-wrapper"
        barClassName="progress-wrapper"
        animated
        value={value}
      />
      <div className="progress-bar-text">
        We are updating your transactions for the card you just linked. This is
        one time only.
      </div>
    </div>
  );
};

export default ProgressBar;
