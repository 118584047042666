import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { previousIco } from 'containers/Assets/images';
import { loggerHelper } from 'utils/utils';
import { logType } from 'types/logTypes';

import RouteNames from '../../app/RouteNames';

const menuCategories = [
  {
    name: 'Linked Accounts',
    link: RouteNames.myProfile.subPath.linkedAccount,
    onclick: () => {
      loggerHelper(logType.clicked_myaccount_list_linkedaccounts);
    },
  },
  {
    name: 'Refer a Friend',
    link: RouteNames.myProfile.subPath.refer,
    onclick: () => {
      loggerHelper(logType.clicked_myaccount_list_referafriend);
    },
  },
  {
    name: 'Profile',
    link: RouteNames.myProfile.subPath.profile,
    onclick: () => {
      loggerHelper(logType.clicked_myaccount_list_profile);
    },
  },
  {
    name: 'Privacy & Security',
    link: RouteNames.myProfile.subPath.privacySecurity,
    onclick: () => {
      loggerHelper(logType.clicked_myaccount_list_privacy_security);
    },
  },
  {
    name: 'Help & Legal',
    link: RouteNames.myProfile.subPath.help,
    onclick: () => {
      loggerHelper(logType.clicked_myaccount_list_help_legal);
    },
  },
  {
    name: 'Contact Us',
    link: RouteNames.myProfile.subPath.contact,
    onclick: () => {
      loggerHelper(logType.clicked_myaccount_list_contactus);
    },
  },
];

export default function MenuProfile(props: any) {
  const history = useHistory();
  const handleLogout = () => {
    loggerHelper(logType.clicked_myaccount_list_signout);
    localStorage.clear();
    window.location.replace('https://www.uthrive.club/');
  };

  useEffect(() => {
    loggerHelper(logType.page_myaccount_load);
  }, []);

  return (
    <div className="menu-profile-wrapper">
      <div className="text-center header-back-grp">
        <button
          onClick={() => {
            history.push({ pathname: '/' });
            loggerHelper(logType.page_myaccount_exit);
          }}
          type="button"
          className={`"btn btn-select float-left pl-0`}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">My Account</span>
      </div>
      <div className="container-menu-categories row ml-0 mr-0">
        <div className="col-md-10 row content-menu-categories ml-auto mr-auto">
          <div className="col-md-6 box-categories box-left">
            {menuCategories.slice(0, 4).map((val, index) => (
              <Link
                to={val.link}
                key={index}
                className="category-item"
                onClick={val.onclick}
              >
                <p className="mb-0 category-menu-name">{val.name}</p>
                <span className="arrow-right">{'ᐳ'}</span>
              </Link>
            ))}
          </div>
          <div className="col-md-6 box-categories box-right">
            {menuCategories.slice(4).map((val, index) => (
              <Link
                to={val.link}
                key={index}
                className="category-item"
                onClick={val.onclick}
              >
                <p className="mb-0 category-menu-name">{val.name}</p>
                <span className="arrow-right">{'ᐳ'}</span>
              </Link>
            ))}
            <div className="category-item">
              <p
                className="mb-0 category-menu-name sign-out-name"
                onClick={handleLogout}
              >
                Sign Out
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
