import React, { useEffect, useState, useRef } from 'react';
import { previousIco, infoIcoWhiteNew } from 'containers/Assets/images';
import { useHistory } from 'react-router-dom';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useMyAccountFormSlice } from './slice';
import { selectProfileData } from './slice/selectors';
import InputCustom from 'components/common/InputTopLabel';
import { isEmpty } from 'lodash';
import RouteNames from 'app/RouteNames';
import { loggerHelper } from 'utils/utils';
import { logType } from 'types/logTypes';
import { ModalItems } from 'components/common';

export default function ProfileInfo(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useMyAccountFormSlice();
  const profileData = useSelector(selectProfileData);
  const isSetInitData = useRef(false);
  const [isOpenWarning, setOpenWarning] = useState(false);
  const [dataForm, setDataForm] = useState({
    firstName: '',
    lastName: '',
    preferredName: '',
    middleInitial: '',
    streetAddress: '',
    apartmentNumber: '',
    city: '',
    state: '',
    zipcode: '',
  });

  const handleChangeValue = event => {
    dataForm[event.target.name] = event.target.value;
    setDataForm({ ...dataForm });
  };

  const handleGetMyProfile = () => {
    dispatch(actions.getProfile());
  };

  const handleUpdateMyProfile = () => {
    loggerHelper(logType.clicked_profile_button_confirm);
    dispatch(
      actions.updateProfile({
        ...dataForm,
        cb: () => {
          history.goBack();
        },
      }),
    );
  };
  const toggleModalWarning = () => {
    setOpenWarning(!isOpenWarning);
  };

  useEffect(() => {
    if (isSetInitData.current) {
      return;
    }
    if (profileData && !isEmpty(profileData) && !isSetInitData.current) {
      setDataForm({ ...profileData });
      isSetInitData.current = true;
    }
  }, [profileData]);
  useEffect(() => {
    handleGetMyProfile();
  }, []);

  return (
    <div className="my-profile-info-wrapper update-profile-info-wrapper">
      <div className="text-center header-back-grp">
        <button
          type="button"
          className={`"btn btn-select float-left pl-0`}
          onClick={() => history.goBack()}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">Profile</span>
        <span
          className="right-text"
          onClick={() => {
            toggleModalWarning();
            // history.push(RouteNames.myProfile.subPath.profile);
            // loggerHelper(logType.clicked_profile_link_cancel);
          }}
        >
          Cancel
        </span>
      </div>
      <div className="container-account-info">
        <div className="col-md-10 row content-account-info ml-auto mr-auto">
          <div className="col-md-10 ml-auto mr-auto p-0 row">
            <div className="col-6 col-sm-6 box-info box-info-left">
              <InputCustom
                label="First name"
                name="firstName"
                onChange={handleChangeValue}
                value={dataForm.firstName}
                required
              />
            </div>
            <div className="col-6 col-sm-6 box-info box-info-right">
              <InputCustom
                label="Last name"
                name="lastName"
                onChange={handleChangeValue}
                value={dataForm.lastName}
              />
            </div>
            <div className="col-6 col-sm-6 box-info box-info-left">
              <UncontrolledPopover
                placement="top"
                target="popver-info"
                className="popver-custom"
                trigger="focus"
              >
                <div className="x-close-popver">X</div>
                <PopoverBody>
                  Uthrive will address you with this name when communicating
                  with you.
                </PopoverBody>
              </UncontrolledPopover>
              <InputCustom
                renderLabel={() => (
                  <label
                    className="input-label"
                    onClick={e => e.preventDefault()}
                  >
                    Preferred name{' '}
                    <button
                      className="bnt-img"
                      id="popver-info"
                      onClick={e => e.currentTarget.focus()}
                    >
                      <img
                        src={infoIcoWhiteNew}
                        alt="info"
                        className="info-img"
                      />
                    </button>
                  </label>
                )}
                value={dataForm.preferredName}
                name="preferredName"
                onChange={handleChangeValue}
              />
            </div>
            <div className="col-6 col-sm-6 box-info box-info-right">
              <InputCustom
                name="middleInitial"
                onChange={handleChangeValue}
                label="Middle initial"
                value={dataForm.middleInitial}
              />
            </div>
            <div className="col-md-12  box-info box-info-left">
              <InputCustom
                label="Street Address"
                name="streetAddress"
                onChange={handleChangeValue}
                value={dataForm.streetAddress}
              />
            </div>
            <div className="col-6 col-sm-6 box-info box-info-left">
              <InputCustom
                label="Apartment #"
                value={dataForm.apartmentNumber}
                name="apartmentNumber"
                onChange={handleChangeValue}
              />
            </div>
            <div className="col-6 col-sm-6 box-info box-info-right">
              <InputCustom
                label="City"
                value={dataForm.city}
                name="city"
                onChange={handleChangeValue}
              />
            </div>
            <div className="col-6 col-sm-6 box-info box-info-left">
              <InputCustom
                label="State"
                value={dataForm.state}
                name="state"
                onChange={handleChangeValue}
              />
            </div>
            <div className="col-6 col-sm-6 box-info box-info-right">
              <InputCustom
                label="Zip code"
                value={dataForm.zipcode}
                name="zipcode"
                onChange={handleChangeValue}
              />
            </div>
            <div className="col-md-6 col-sm-6 box-info box-info-left phone-input-disable">
              <InputCustom
                label="Phone Number"
                value={profileData?.phoneNumber?.trim()}
                name="phoneNumber"
                onChange={handleChangeValue}
                readOnly
              />
            </div>
            <div className="col-md-6  box-info box-info-left email-input-disable">
              <InputCustom
                label="Email Address"
                value={profileData?.email?.trim()}
                name="email"
                onChange={handleChangeValue}
                readOnly
              />
            </div>
            <div className="box-bnt-submit">
              <button
                type="button"
                className="bnt-submit"
                onClick={handleUpdateMyProfile}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalItems
        isOpen={isOpenWarning}
        className="modal-blur modal-warning-wrapper"
        toggle={toggleModalWarning}
      >
        <div className="modal-warning-contents">
          <div className="text-warning-exist">
            Are you sure you want to cancel, any changes made will not be saved
            if you do not click "Confirm".
          </div>
          <div className="btn-group">
            <button
              onClick={toggleModalWarning}
              type="button"
              className="btn btn-secondary"
            >
              Back
            </button>
            <button
              onClick={() => {
                history.push(RouteNames.myProfile.subPath.profile);
                loggerHelper(logType.clicked_profile_link_cancel);
              }}
              type="button"
              className="btn btn-base "
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalItems>
    </div>
  );
}
