import React from 'react';
import InputGroup from '../InputGroup';
import SSNGroup from '../SSNGroup';
import PhoneGroup from '../PhoneGroup';
import OTPGroup from '../OTPGroup';
import DateTimePicker from '../DateTimePicker';
import PlacesAutocompleteGroup from '../PlacesAutocompleteGroup';

export const GenericInput = ({ type, ...props }) => {
  switch (type) {
    case 'phone':
      return <PhoneGroup type={type} {...props} />;
    case 'ssn':
      return <SSNGroup type={type} {...props} />;
    case 'otp':
      return <OTPGroup type={type} {...props} />;
    case 'date':
      return <DateTimePicker {...props} />;
    case 'text':
      return <InputGroup type={type} {...props} />;
    case 'map':
      return <PlacesAutocompleteGroup {...props} />;
    default:
      return <InputGroup type={type} {...props} />;
  }
};
