/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Amplify from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer';
import airbridge from 'airbridge-web-sdk-loader';
import Router from './Router';
import { checkTokenExist } from 'utils/utils';

import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
});

airbridge.init({
  app: process.env.REACT_APP_AIRBRIDGE_APP_NAME || 'uthriveapp',
  webToken: process.env.REACT_APP_AIRBRIDGE_TOKEN || '',
});

export function App() {
  const { i18n } = useTranslation();
  const handleOnIdle = event => {
    // console.log('last active', getLastActiveTime());
    if (!checkTokenExist()) {
      return;
    }
    localStorage.clear();
    window.location.replace('https://www.uthrive.club/');
  };

  const handleOnActive = event => {
    // console.log('user is active', event);
    // console.log('time remaining', getRemainingTime());
  };

  const handleOnAction = event => {
    // console.log('user did something', event);
  };

  useIdleTimer({
    timeout: 1000 * 270,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 0,
  });

  return (
    // <BrowserRouter>
    <div>
      <Helmet
        titleTemplate="Uthrive"
        defaultTitle="Uthrive"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Uthrive" />
      </Helmet>
      <Router />
      <ToastContainer />
    </div>
  );
}
