import React, { useEffect } from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { previousIco, infoIcoWhiteNew } from 'containers/Assets/images';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMyAccountFormSlice } from './slice';
import { selectProfileData } from './slice/selectors';
import RouteNames from 'app/RouteNames';
import { loggerHelper } from 'utils/utils';
import { logType } from 'types/logTypes';

export default function ProfileInfo(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useMyAccountFormSlice();
  const profileData = useSelector(selectProfileData);

  const handleGetMyProfile = () => {
    dispatch(actions.getProfile());
  };

  useEffect(() => {
    handleGetMyProfile();
    loggerHelper(logType.page_profile_load);
  }, []);
  return (
    <div className="my-profile-info-wrapper">
      <div className="text-center header-back-grp">
        <button
          type="button"
          className={`"btn btn-select float-left pl-0`}
          onClick={() => {
            history.push('/my-account');
            loggerHelper(logType.page_profile_exit);
          }}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">Profile</span>
        <Link
          to={RouteNames.myProfile.subPath.updatedProfile}
          className="right-text cursor"
          onClick={() => {
            loggerHelper(logType.clicked_profile_link_edit);
          }}
        >
          Edit
        </Link>
      </div>
      <div className="container-account-info">
        <div className="col-md-10 row content-account-info ml-auto mr-auto">
          <div className="col-md-10 ml-auto mr-auto p-0 row">
            <div className="col-6 col-sm-6 box-info">
              <p className="form-info-label">First name</p>
              <p className="form-info-value">{profileData?.firstName || ''}</p>
            </div>
            <div className="col-6 col-sm-6 box-info">
              <p className="form-info-label">Last name</p>
              <p className="form-info-value">{profileData?.lastName || ''}</p>
            </div>
            <div className="col-6 col-sm-6 box-info">
              <UncontrolledPopover
                placement="top"
                target="popver-info"
                className="popver-custom"
                trigger="focus"
              >
                <div className="x-close-popver">X</div>
                <PopoverBody>
                  Uthrive will address you with this name when communicating
                  with you.
                </PopoverBody>
              </UncontrolledPopover>
              <p className="form-info-label" onClick={e => e.preventDefault()}>
                Preferred name{' '}
                <button
                  className="bnt-img"
                  id="popver-info"
                  onClick={e => e.currentTarget.focus()}
                >
                  <img src={infoIcoWhiteNew} alt="info" className="info-img" />
                </button>
              </p>
              <p className="form-info-value">
                {profileData?.preferredName || ''}
              </p>
            </div>
            <div className="col-6 col-sm-6 box-info">
              <p className="form-info-label">Middle initial</p>
              <p className="form-info-value">
                {profileData?.middleInitial || ''}
              </p>
            </div>
            <div className="col-12  box-info">
              <p className="form-info-label">Street Address</p>
              <p className="form-info-value">
                {profileData?.streetAddress || ''}
              </p>
            </div>
            <div className="col-6 col-sm-6 box-info">
              <p className="form-info-label">Apartment #</p>
              <p className="form-info-value">
                {profileData?.apartmentNumber || ''}
              </p>
            </div>
            <div className="col-6 col-sm-6 box-info">
              <p className="form-info-label">City</p>
              <p className="form-info-value">{profileData?.city || ''}</p>
            </div>
            <div className="col-6 col-sm-6 box-info">
              <p className="form-info-label">State</p>
              <p className="form-info-value">{profileData?.state || ''}</p>
            </div>
            <div className="col-6 col-sm-6 box-info">
              <p className="form-info-label">Zip code</p>
              <p className="form-info-value">{profileData?.zipcode || ''}</p>
            </div>
            <div className="col-md-6 box-info">
              <p className="form-info-label">Phone Number</p>
              <p className="form-info-value">
                {profileData?.phoneNumber || ''}
              </p>
            </div>
            <div className="col-md-6  box-info">
              <p className="form-info-label">Email Address</p>
              <p className="form-info-value">{profileData?.email || ''}</p>
            </div>
            <div className="col-md-6  link-delete">
              <Link
                to={RouteNames.myProfile.subPath.deleteAccount}
                className="form-info-label"
                onClick={() =>
                  loggerHelper(logType.clicked_deleteaccount_feedback)
                }
              >
                Delete Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
