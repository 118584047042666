import * as React from 'react';
import { formatMoneyValue } from 'utils/utils';
import { minusIco, plusIco, creditCard } from 'containers/Assets/images';
import { logType } from 'types/logTypes';
import { loggerHelper } from 'utils/utils';

export function RecommendationCardDetails(props) {
  const { data, subTitle, onChange } = props;
  return (
    <div className="card-details col-md-12 p-0 d-flex">
      <div className="col-md-3 p-0 pr-3 img-card-grp img-card-grp-earned">
        <img
          src={data.categoryImageUrl || creditCard}
          alt="img"
          width="75px"
          height="47px"
          className="img-item-custom-recommendation"
        />
      </div>
      <div className="col-md-9 row rw-card-grp-earned">
        <div className="m-auto row container-calc d-flex justify-content-end">
          <div className="p-0 spent-item-recommended">
            <div className="spent-title margin-bottom-5">Spent this year</div>
            <div className="spent-price nowrap">{`$${
              data?.totalSpend ? formatMoneyValue(data?.totalSpend, true) : 0
            }`}</div>
          </div>
          <div className="p-0 rewards-item-recommended min-width-100">
            <div className="rewards-title margin-bottom-5">
              {subTitle || 'Next year spend'}
            </div>
            <div className="rewards-price nowrap d-flex">
              <img
                src={minusIco}
                alt=""
                className="cursor"
                width="18px"
                height="18px"
                onClick={evt => {
                  onChange(data?.value - 5);
                  loggerHelper(
                    logType.clicked_custom_recommendations_link_decrease,
                  );
                }}
              />
              <div className="m-auto">
                {data?.increase === true
                  ? `+${data?.value}%`
                  : `${data?.value}%`}
              </div>
              <img
                src={plusIco}
                alt=""
                className="cursor"
                width="18px"
                height="18px"
                onClick={evt => {
                  onChange(data?.value + 5);
                  loggerHelper(
                    logType.clicked_custom_recommendations_link_increase,
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
