import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useListenerRewardsNew } from 'utils/socketIO';
import { useProgressSlice } from './slice';
import ProgressBar from 'components/common/ProgressBar';
import { selectLinking } from './slice/selector';

const Layout = ({ children }) => {
  const isLoadingOn = useSelector(selectLinking);
  const { actions } = useProgressSlice();
  const dispatch = useDispatch();
  const [percent, setPercent] = React.useState(25);
  const [, addConnect, listenUser] = useListenerRewardsNew(data => {
    setPercent(data?.data?.loading);
    if (!isLoadingOn && data?.data?.loading !== 100) {
      dispatch(actions.setLinkingOn());
      return;
    }
    if (data?.data?.loading === 100) {
      setTimeout(() => {
        dispatch(actions.setLinkingOff());
      }, 500);
    }
  });
  React.useEffect(() => {
    listenUser();
    addConnect();
  }, []);
  return (
    <div>
      {children}
      {isLoadingOn && <ProgressBar value={percent || 25} />}
    </div>
  );
};

export default Layout;
