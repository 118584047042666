import iconRestaurant from 'containers/Assets/images/restaurant.svg';
import iconGas from 'containers/Assets/images/gas.svg';
import iconGroceries from 'containers/Assets/images/groceries.svg';
import iconCoffee from 'containers/Assets/images/coffee.svg';

export const ONBOARDINGS = [
  {
    id: '1',
    label: 'View rewards you are missing',
    uri:
      'https://f16.photo.talk.zdn.vn/8849790215965534516/407eb8b19840511e0851.jpg',
  },
  {
    id: '2',
    label: 'And how to get them',
    uri:
      'https://f14.photo.talk.zdn.vn/3392051073402673820/5223a1ef811e4840110f.jpg',
  },
  {
    id: '3',
    label: 'See the best card to use',
    uri:
      'https://f11.photo.talk.zdn.vn/8778774964957569102/61b99075b08479da2095.jpg',
  },
  {
    id: '4',
    label: 'Even at places near you',
    uri:
      'https://f4.photo.talk.zdn.vn/2915984982144035007/9fa7646b449a8dc4d48b.jpg',
  },
];

export const MAPCATEGORIES = [
  {
    key: 'restaurants',
    label: 'Restaurants',
    url: iconRestaurant,
  },
  {
    key: 'gas',
    label: 'Gas',
    url: iconGas,
  },
  {
    key: 'groceries',
    label: 'Groceries',
    url: iconGroceries,
  },
  {
    key: 'coffee',
    label: 'Coffee',
    url: iconCoffee,
  },
  // {
  //     key: 'hotel',
  //     label: 'Hotel',
  //     url: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/lodging-71.png'
  // },
  // {
  //     key: 'hospital',
  //     label: 'Hospital',
  //     url: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/hospital-71.png'
  // },
  // {
  //     key: 'atm',
  //     label: 'Atm',
  //     url: 'https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/atm-71.png'
  // },
];

export const linkOurStory = 'https://www.uthrive.club/about-us';

export const linkPlayStore =
  'https://uthrive.app.link/download-uthrive-android';

export const linkAppleStore = 'https://uthrive.app.link/download-uthrive-ios';

export const linkMedia = 'https://www.uthrive.club/media';
