import { prettifyDoaminPath, pathNames } from '../path';
import axios from 'axios';

export const getLinkedAccount = () =>
  axios.get(`${prettifyDoaminPath(pathNames.linkedAccount)}`);

export const getMyProfile = () =>
  axios.get(`${prettifyDoaminPath(pathNames.myProfile)}`);

export const updateMyProfile = params =>
  axios.put(`${prettifyDoaminPath(pathNames.updateProfile)}`, params);

export const getNotificationPreference = () =>
  axios.get(`${prettifyDoaminPath(pathNames.notificationPreference)}`);

export const updateNotificationPreference = params =>
  axios.put(`${prettifyDoaminPath(pathNames.notificationPreference)}`, params);

export const postContactUs = params =>
  axios.post(`${prettifyDoaminPath(pathNames.contactUs)}`, params);

export const getHelpQuestion = () =>
  axios.get(`${prettifyDoaminPath(pathNames.helpQuestion)}`);

export const deleteAccount = body =>
  axios.delete(prettifyDoaminPath(pathNames.deleteAccount), { data: body });

export const getOTP = () =>
  axios.get(`${prettifyDoaminPath(pathNames.getOTP)}`);

export const getTokenPlaid = ({ id, params }) =>
  axios.get(`${prettifyDoaminPath(pathNames.tokenPlaid)}/${id}`, { params });

export const getLinkedAccountById = ({ id }) =>
  axios.get(`${prettifyDoaminPath(pathNames.linkedAccountById)}/${id}`);

export const unlinkAccountPlaid = params =>
  axios.post(`${prettifyDoaminPath(pathNames.unlinkAccountPlaid)}`, params);

export const relinkAccountPlaid = params =>
  axios.post(`${prettifyDoaminPath(pathNames.relinkAccountPlaid)}`, params);

export const addCardAccountPlaid = params =>
  axios.post(`${prettifyDoaminPath(pathNames.addCardAccountPlaid)}`, params);
