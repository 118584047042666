import React from 'react';
import { fieldValidation } from 'containers/Assets/images';
import NumberFormat from 'react-number-format';
import { loggerHelper } from 'utils/utils';

const SSNGroup = ({
  isValid,
  label,
  type,
  id,
  elm,
  wrapperClass,
  onChange,
  name,
  validText,
  isShowImgValide,
  value,
  rightLink,
  onShowPopup,
  onBlur,
  logger,
  ...rest
}: any) => {
  const idRandom = id || `${Math.random().toString().replace('.', '')}`;
  return (
    <div className={`form-group p-0 m-0 ${wrapperClass}`}>
      <div className="ssn-grp">
        <span className="ssn-label">****-**-</span>
        <NumberFormat
          onChange={evt => onChange({ name, value: evt.target.value })}
          value={value}
          onBlur={() => {
            if (onBlur) onBlur();
            if (logger) loggerHelper(logger);
          }}
          {...rest}
          className="number-input-ssn"
          format="####"
          mask="_"
        />
      </div>
      {isShowImgValide && (
        <img
          src={fieldValidation}
          className="validation-ssn"
          alt="img-validation"
          height="30px"
          width="30px"
        />
      )}
      {label && (
        <label className="small-font-size" htmlFor={idRandom}>
          {label || ''}
        </label>
      )}
      {rightLink && (
        <button
          className="btn-text no-border small-font-size p-0 cursor float-right info-link"
          onClick={onShowPopup}
        >
          {rightLink || ''}
        </button>
      )}
      {isValid && (
        <div className="small-font-size valid-text">{validText || ''}</div>
      )}
      {elm ? elm : ''}
    </div>
  );
};

export default SSNGroup;
