import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LogoFont } from '../common';
import {
  facebookIco,
  instagramIco,
  twitterIco,
  linkedInIco,
  youtubeIco,
  playStore,
  appleStore,
} from 'containers/Assets/images';
import RouteNames from 'app/RouteNames';
import {
  linkOurStory,
  linkAppleStore,
  linkPlayStore,
  linkMedia,
} from 'utils/constants';
import { checkUserVerified } from 'utils/utils';

const btnSocialList = [
  {
    ico: facebookIco,
    link: 'https://www.facebook.com/UTHRIVE.CLUB',
  },

  {
    ico: instagramIco,
    link: 'https://www.instagram.com/uthrive.club/',
  },
  {
    ico: twitterIco,
    link: 'https://twitter.com/Uthrive_Club',
  },
  {
    ico: linkedInIco,
    link: 'https://www.linkedin.com/company/uthrive-inc/',
  },
  {
    ico: youtubeIco,
    link: 'https://www.youtube.com/channel/UCPBBbnm31tcG3DHy0LoAhlw',
  },
];

export function Footer(props) {
  const { wrapperClass } = props;
  const history = useHistory();
  const isValid = checkUserVerified();
  return (
    <div
      className={`navbar footer-item-grp ${
        wrapperClass || ''
      } footer-wrapper safari_only`}
    >
      <div className="col-md-12 row logo-container">
        <LogoFont wrapperClass="footer-logo" isWhiteLogo />
      </div>
      <div className="col-md-12 row">
        <div className="col-md-10 col-sm-6 col-6 row pl-0 m-0 pr-0">
          <div className="col-md-4 box-section pl-0">
            <p className="title-section">About</p>
            <p
              className="sub-title-section cursor"
              onClick={() => {
                window.open(linkOurStory);
              }}
            >
              Our Story
            </p>
            <p
              className="sub-title-section cursor"
              onClick={() => {
                window.open(linkMedia);
              }}
            >
              Uthrive in Media
            </p>
            {/* <p className="sub-title-section">press@Uthrive.club</p> */}
            <div className="social-grp">
              {btnSocialList.map((val, index) => (
                <div key={index} className="social-item-box">
                  <a href={val.link} target="_blank" rel="noreferrer">
                    <img
                      src={val.ico}
                      alt="img-nav"
                      className="social-icon"
                      height="20px"
                      width="20px"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-4 box-section">
            <p className="title-section">Links you may like</p>
            <p
              className="sub-title-section cursor"
              onClick={() => {
                window.open('https://www.uthrive.club/blog', '_blank');
              }}
            >
              The Blog
            </p>
          </div>
          <div className="col-md-4 box-section">
            <p className="title-section box-section">Help & Legal</p>
            <p
              className="sub-title-section cursor"
              onClick={() => {
                isValid
                  ? history.push(RouteNames.myProfile.subPath.help)
                  : window.open('https://www.uthrive.club/help', '_blank');
              }}
            >
              Help
            </p>
            <p className="sub-title-section">support@Uthrive.club</p>
            <p
              className="sub-title-section cursor"
              onClick={() => {
                window.open(
                  `${window.location.origin}${RouteNames.termsConditionsWeb.path}`,
                  '_blank',
                );
              }}
            >
              Terms & Conditions
            </p>
            <p
              className="sub-title-section cursor"
              onClick={() => {
                window.open(
                  `${window.location.origin}${RouteNames.privacyPolicy.path}`,
                  '_blank',
                );
              }}
            >
              Privacy Policy
            </p>
          </div>
        </div>

        <div className="col-md-2 col-sm-6 col-6 d-flex justify-content-end">
          <div>
            <p className="title-section box-section box-section-app">App</p>

            <img
              src={playStore}
              onClick={() => window.open(linkPlayStore)}
              className="backgroundLanding--store cursor"
              alt="img-landing"
              height="100%"
              width="100%"
            />
            <img
              src={appleStore}
              onClick={() => window.open(linkAppleStore)}
              className="backgroundLanding--store cursor"
              alt="img-landing"
              height="100%"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div className="col-md-12 pl-0 white-color mono-font-size des-section">
        All corporate logos and trademarks are for illustrative purposes only
        and are not a recommendation, an offer to sell, or a solicitation of an
        offer. Trademarks and logos are the property of their respective owners
        and do not represent endorsements of any kind. Examples and/or pictures
        above are for illustrative purposes only and are not real customers.
        View our{' '}
        <Link to={RouteNames.myProfile.subPath.acknowledgement}>
          <u className="font-weight-bold cursor text-white">Disclaimer</u>
        </Link>{' '}
        for more details.
      </div>
      <div className="copy-right-section">
        © Copyright 2021 Uthrive Inc. All rights reserved.
      </div>
    </div>
  );
}
