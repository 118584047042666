import React, { useEffect } from 'react';
import { useDeleteAccount } from './DeleteAccountProvider';
import { CountdownTimerButton, GenericInput } from 'components/common';
import { loggerHelper, secretEmail } from 'utils/utils';
import { getUserRegister } from 'utils/localStorages';
import { logType } from 'types/logTypes';

export default function FormSubmitText() {
  const {
    messageErrorOTP,
    setMessageErrorOTP,
    timmerOtp,
    setTimerOtp,
    handleCheckOTP,
    resendOtp,
    invalidCounter,
  } = useDeleteAccount();
  const userLogin = getUserRegister();
  useEffect(() => {
    loggerHelper(logType.page_deleteaccount_otp_load);
    return () => {
      loggerHelper(logType.page_deleteaccount_otp_exit);
    };
  }, []);
  return (
    <div className="col-md-10 p-0 row ml-auto mr-auto ask-confirm-wrapper validate-otp-wrapper">
      <div className="col-md-10 ml-auto mr-auto content-menu-categories">
        <div className="title-confirm text-center">{`We sent you a 6-digit code to your email ${secretEmail(
          userLogin?.email_address,
        )} and phone (if provided), please verify the code:`}</div>

        <div className="box-otp">
          <GenericInput
            wrapperClass="col-md-12 pt-100"
            onChange={handleCheckOTP}
            type="otp"
            name="otp"
            label=""
          />
          <div className="mt-3 small-font-size otp-resend">
            Didn’t get the code?
            <CountdownTimerButton
              data={timmerOtp}
              label="Resend."
              wrapperClass="text-bold"
              onClick={() => {
                resendOtp();
                loggerHelper(logType.clicked_deleteaccount_otp_link_resend);
              }}
              onClickTimerResend={() => {
                setTimerOtp({
                  timmer: Date.now() + 59000,
                  key: Date.now().toString(),
                });
                setMessageErrorOTP(
                  'Too quick! Please wait for 1 minute before clicking ‘Resend’',
                );
                setTimeout(() => {
                  setMessageErrorOTP('');
                }, 60000);
              }}
              disableResend={invalidCounter === 3}
            />
          </div>
          {!!messageErrorOTP && (
            <div className="small-font-size valid-text valid-text-otp text-left">
              {messageErrorOTP}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
