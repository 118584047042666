import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.homeForm || initialState;

export const selectPlaidData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.plaidData,
);

export const selectValidPlaidData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.isPlaidValidateData,
);

export const selectValidPlaidLinkFail = createSelector(
  [selectDomain],
  homeFormState => homeFormState.isPlaidLinkFail,
);

export const selectValidPlaidLinkFailData = createSelector(
  [selectDomain],
  homeFormState => [homeFormState.plaidFailMessage, homeFormState.plaidFailId],
);

export const selectRewardSummaryData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardSummaryData,
);

export const selectRewardEarnedData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardEarnedData,
);

export const selectRewardEarnedDataById = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardEarnedDataById,
);

export const selectRewardMissedData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardMissedData,
);

export const selectRewardMissedDetailsData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardMissedDetailsData,
);

export const selectPromptQuestion = createSelector(
  [selectDomain],
  homeFormState => homeFormState.promptData,
);

export const selectRecommendedData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardRecommendedData,
);

export const selectRecommendedDetailsData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardRecommendedDetailsData,
);

export const selectRecommendedSuggestedData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardRecommendedSuggestedData,
);

export const selectRecommendedCustomizesData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.rewardRecommendedCustomizesData,
);

export const selectReferralData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.referralData,
);
// ----------------------------------------------------------------
export const selectCardsMappingFailed = createSelector(
  [selectDomain],
  homeFormState => homeFormState.cardsMappingFailed,
);

export const selectCardsLinked = createSelector(
  [selectDomain],
  homeFormState => homeFormState.cardsLinked,
);
