import React from 'react';
import { ModalItems } from '../common';
import { imgOnWorking } from 'containers/Assets/images';

export function ModalNotFoundStore({ isOpenModal, toggle }) {
  return (
    <ModalItems
      toggle={toggle}
      className="modal-lg modal-estimate-wrapper modal-not-found-store-wrapper"
      modalClass="modal-estimate-container border-radius"
      isOpen={isOpenModal}
      isSupportClose
    >
      <div className="col-md-12 m-auto modal-estimate-content p-0">
        <div className="title-modal">We are working on it.</div>
        <div className="description-modal">
          We are currently working on to add more merchants on Uthrive™. Please
          bear with us. If you would like to submit a merchant name, send us a
          secured message from My Account &gt; Contact Us.
        </div>
        <div>
          <img alt="img-banner" src={imgOnWorking} className="img-banner" />
        </div>
      </div>
    </ModalItems>
  );
}
