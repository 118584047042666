import React from 'react';
import { loggerHelper } from 'utils/utils';

export const SelectionGroup = ({
  selectionList,
  activeTabName,
  onToggleTab,
  isActiveMostRecent,
}: any) => {
  return (
    <div>
      {selectionList.map(val => (
        <button
          key={val.tabName}
          className={`btn-tab-select pr-3 cursor ${
            activeTabName && val.tabName === activeTabName ? 'active' : ''
          }`}
          onClick={() => {
            onToggleTab({ tabName: val.tabName });
            if (activeTabName && activeTabName !== val.tabName && val.logger) {
              loggerHelper(val.logger);
            }
          }}
          disabled={val.tabName === 'mostRecent' && !isActiveMostRecent}
        >
          <div className="title-select">{val.title}</div>
          {activeTabName && val.tabName === activeTabName && (
            <div className="border-select" />
          )}
        </button>
      ))}
    </div>
  );
};
