import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from 'containers/Assets/images/Marker';

const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const CustomMarker: any = ({ onClick, color, icon }) => (
  <div className="marker-custom-wrapper" onClick={onClick}>
    <Marker color={color || ''} />
    <img
      className="img-icon"
      alt="icon"
      src={icon}
      style={{ backgroundColor: icon }}
    />
  </div>
);

class MapCustomMarker extends React.Component<any, any> {
  render() {
    const { listResult = [], onSelectMarker, center } = this.props;

    return (
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          center={center || { lat: 0, lng: 0 }}
          zoom={16}
        >
          {listResult.map((item: any, index: any) => (
            <CustomMarker
              key={index}
              lat={item?.geometry?.location?.lat}
              lng={item?.geometry?.location?.lng}
              onClick={() => onSelectMarker(item)}
              color={item.icon_background_color}
              icon={item.icon}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
  }
}

export default MapCustomMarker;
