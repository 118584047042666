import React from 'react';
import Countdown from 'react-countdown';

export const CountdownTimerButton = ({
  data,
  label,
  wrapperClass,
  onClickTimerResend,
  disableResend,
  ...rest
}: any) => {
  return (
    <Countdown
      date={data.timmer}
      key={data.key}
      renderer={({ seconds, completed }) => {
        if (completed) {
          return (
            <button
              className={`no-border btn-text ${wrapperClass || ''}`}
              {...rest}
            >
              {label}
            </button>
          );
        }
        return (
          <span>
            <button
              className={`no-border btn-text ${wrapperClass || ''}`}
              onClick={onClickTimerResend}
              disabled={disableResend}
            >
              {label}
            </button>
            {/* <span>{`( ${seconds} )`}</span> */}
          </span>
        );
      }}
    />
  );
};
