import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UncontrolledCollapse } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMyAccountFormSlice } from './slice';
import { selectHelpQuestionData } from './slice/selectors';
import { previousIco, nextIco } from 'containers/Assets/images';

function RenderAnswer(props) {
  const text = props.text || '';
  const newText = text.split('\n').map((str, idx) => (
    <p key={idx} style={{ minHeight: 1, marginBottom: 4 }}>
      {str}
    </p>
  ));
  return newText;
}

export default function CustomerCare(props: any) {
  const history = useHistory();
  const { actions } = useMyAccountFormSlice();
  const dispatch = useDispatch();
  const helpQuestionData = useSelector(selectHelpQuestionData) || {};

  const handleGetHelpQuestion = () => {
    dispatch(actions.getHelpQuestion());
  };

  useEffect(() => {
    handleGetHelpQuestion();
  }, []);
  return (
    <div className="menu-profile-wrapper help-wrapper customer-wrapper">
      <div className="text-center header-back-grp">
        <button
          type="button"
          className={`"btn btn-select float-left pl-0`}
          onClick={() => history.goBack()}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">Customer Care</span>
      </div>
      <div className="container-menu-categories row ml-0 mr-0">
        <div className="col-md-10 row content-menu-categories ml-auto mr-auto">
          {Object.keys(helpQuestionData || {})?.length
            ? Object.keys(helpQuestionData).map((keyName, parentIndex) => (
                <div
                  className={`col-md-6 box-categories ml-0  ${
                    parentIndex % 2 === 0 ? 'box-left' : 'box-right'
                  }`}
                  key={keyName.replace(/\s/g, '')}
                >
                  <p className="text-box-section">{keyName}</p>
                  {helpQuestionData[keyName]?.length
                    ? helpQuestionData[keyName].map((val, index) => (
                        <div
                          key={`${keyName}-${index}`}
                          className="category-item d-block"
                          id={`card-left-question-${index}-${parentIndex}`}
                        >
                          <div className="d-flex justify-content-between w-100">
                            <p className="mb-0 category-menu-name">
                              {val?.questionText}
                            </p>
                            <img
                              src={nextIco}
                              alt="arrow-right-icon"
                              className="arrow-right-icon"
                            />
                          </div>
                          <UncontrolledCollapse
                            toggler={`#card-left-question-${index}-${parentIndex}`}
                            className="collapse-penal"
                          >
                            <div className="content-question">
                              <RenderAnswer
                                text={
                                  val?.answers?.length
                                    ? val?.answers?.join(' ')
                                    : ''
                                }
                              />
                            </div>
                          </UncontrolledCollapse>
                        </div>
                      ))
                    : null}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}
