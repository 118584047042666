import {
  useState,
  // useEffect
} from 'react';
import io from 'socket.io-client';
import axios from 'api/Axios';
import { getUserCognitoVerified, getUserRegister } from 'utils/localStorages';
import { alertLog } from 'api';
import { includes } from 'lodash';

const SocketIO = (() => {
  let instance;

  const init = ({ token }) => {
    const newToken = axios.defaults.headers.common.Authorization;

    return io(`${process.env.REACT_APP_SOCKET_IO}`, {
      transports: ['polling', 'websocket'],
      jsonp: false,
      query: {
        Authorization: token || newToken,
      },
      rejectUnauthorized: false,
      timeout: 20000,
      reconnection: true,
      reconnectionAttempts: 3,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      upgrade: false,
    });
  };

  return {
    getInstance: query => {
      if (!instance) instance = init(query);

      if (instance.disconnected || !instance.connected) {
        instance.connect();
      }
      return instance;
    },
  };
})();

// export const useListenerRewards = callbackSuccess => {
//   const [rewards, setRewards] = useState(null) as any;
//   const token = axios.defaults.headers.common.Authorization;
//   const listenUser = () => {
//     const socket = SocketIO.getInstance({ token });
//     socket.emit('listenUser', '');
//     // console.log('[[listenUser]]');
//   };

//   const addConnect = () => {
//     const socket = SocketIO.getInstance({ token });

//     socket.on('connect', data => {
//       console.log('[[socket connected]]', data);
//       listenUser();
//     });

//     socket.on('calculatedReward', data => {
//       console.log('[[calculatedReward]]', data);
//       setRewards(data);
//       callbackSuccess(data);
//     });

//     socket.on('listenedUser', data => {
//       console.log('[[listenedUser]]', data);
//     });

//     socket.on('disconnect', () => {
//       console.log('[[socket disconnected]]');
//     });
//   };

//   const disconnect = () => {
//     const socket = SocketIO.getInstance({ token });
//     socket.removeListener('connect');
//     socket.removeListener('calculatedReward');
//     socket.removeListener('listenedUser');
//     socket.removeListener('disconnect');
//   };

//   useEffect(() => {
//     return disconnect;
//   }, []);

//   return [rewards, addConnect, disconnect];
// };

export const useListenerRewardsNew = callbackSuccess => {
  const userCognito = getUserCognitoVerified() || null;
  const tokenLocal =
    userCognito?.signInUserSession?.accessToken?.jwtToken || null;
  const token =
    axios.defaults.headers.common.Authorization || `Bearer ${tokenLocal}`;

  const [rewards, setRewards] = useState(null) as any;
  const listenUser = () => {
    const socket = SocketIO.getInstance({ token });

    socket.emit('listenUser', '');
    console.log('[[listenUser]]');
  };

  const addConnect = () => {
    const socket = SocketIO.getInstance({ token });

    socket.on('connect', data => {
      console.log('[[socket connected]]', data);
      // listenUser();
    });
    socket.on('calculatedReward', data => {
      console.log('[[calculatedReward]]', data);
      setRewards(data);
      callbackSuccess(data);
    });
    socket.on('listenedUser', data => {
      console.log('[[listenedUser]]', data);
    });
    socket.on('disconnect', async error => {
      console.log('[[socket disconnected]]');
      if (includes(['transport close', 'transport error'], error)) {
        return;
      }
      const userDetail = await getUserRegister();

      alertLog({
        alarmName: 'SOCKET_COMMUNICATION_FAILURE',
        userId: userDetail?.userId,
        reason: error || null,
      });
    });
    socket.on('connect_error', async err => {
      const userDetail = await getUserRegister();
      alertLog({
        alarmName: 'SOCKET_COMMUNICATION_FAILURE',
        userId: userDetail?.userId,
      });
    });
    socket.on('connect_failed', async err => {
      console.log('🚀 ~ file: socketIO.ts ~ line 148 ~ addConnect ~ err', err);

      const userDetail = await getUserRegister();
      alertLog({
        alarmName: 'SOCKET_COMMUNICATION_FAILURE',
        userId: userDetail?.userId,
      });
    });
  };
  // const disconnect = () => {
  //   const socket = SocketIO.getInstance({ token });
  //   socket.removeListener('disconnect');
  // };
  // useEffect(() => {
  //   return disconnect;
  // }, []);
  return [rewards, addConnect, listenUser];
};
// export { useListenerRewardsNew };
