import * as React from 'react';
import { LogoFont } from '../common';

export function LoginNavbarItem({
  onHanldeLogin,
  onHanldeSignUp,
  loggerHelper,
}) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light login-nav nav-group navbar-landing">
      <div className="container-fluid container-navbar">
        <div className="logo-navbar">
          <LogoFont loggerHelper={loggerHelper} />
        </div>
        <div className="login-group-btn">
          <button
            type="button"
            className="btn btn-login btn-text text-decoration-none"
            onClick={onHanldeLogin}
          >
            Log in
          </button>
          <button
            type="button"
            className="btn btn-base btn-signup w-btn-signup-responsive"
            onClick={onHanldeSignUp}
          >
            Sign up
          </button>
        </div>
      </div>
    </nav>
  );
}
