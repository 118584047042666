import React, { useState, useEffect } from 'react';
import { ModalItems, CheckGroup } from 'components/common';
import { useDispatch } from 'react-redux';
import { split } from 'lodash';
import { useMyAccountFormSlice } from './slice';
import { capitalizeFirstLetter, loggerHelper } from 'utils/utils';
import { logType } from 'types/logTypes';

export default function PromptModal({
  isOpenModalPrompt,
  onUpdatedPrompt,
  data,
}) {
  const dispatch = useDispatch();
  const { actions } = useMyAccountFormSlice();
  const [idSelected, setIdSelect] = useState(null);
  const [indexQuestion, setIndexQuestion] = useState(0);
  const [questionsData, setQuestionsData] = useState(data || null);
  const rewardPromptQuestionSplit =
    questionsData && questionsData.length
      ? split(questionsData[indexQuestion]?.question, '\n\n', 2)
      : [];

  const isExitQuestion = () => {
    if (
      questionsData &&
      questionsData.length &&
      questionsData[indexQuestion + 1]
    ) {
      setIndexQuestion(indexQuestion + 1);
      return true;
    }
    return false;
  };

  const onSkipUPrompt = () => {
    dispatch(
      actions.skipRewardPrompt({
        id:
          questionsData && questionsData[indexQuestion]
            ? questionsData[indexQuestion]?.id
            : null,
        cb: ({ success }) => {
          if (success) onUpdatedPrompt();
        },
      }),
    );
  };

  const onAnswerPrompt = () => {
    dispatch(
      actions.answerRewardPrompt({
        id:
          questionsData && questionsData[indexQuestion]
            ? questionsData[indexQuestion]?.id
            : null,
        answerIds: [idSelected],
        cb: ({ success }) => {
          if (success && !isExitQuestion()) onUpdatedPrompt();
        },
      }),
    );
  };
  let isFlex = true;
  if (questionsData && questionsData[indexQuestion]?.answer?.length > 2) {
    isFlex = false;
  }

  useEffect(() => {
    if (data) {
      setQuestionsData(data);
    }
  }, [data]);

  useEffect(() => {
    loggerHelper(logType.page_interstetial_card_load);
  }, []);

  return (
    <div>
      <ModalItems
        isOpen={isOpenModalPrompt}
        modalClass="p-5 modal-skip"
        className="modal-blur modal-lg modal-skip modal-margin-auto"
      >
        <div className="body-modal-skip border-radius">
          {/* <div>
            <button
              type="button"
              className="btn-close-modal-skip no-border no-background float-right"
              onClick={onSkipUPrompt}
            >
              X
            </button>
          </div> */}
          <div>
            <div className="prompt-title">
              {(questionsData && questionsData[indexQuestion]?.cardName) || ''}
            </div>
            <div className="question-detail">
              {`${
                rewardPromptQuestionSplit && rewardPromptQuestionSplit[0]
                  ? rewardPromptQuestionSplit[0]
                  : ''
              }`}
            </div>
            <div className="border-bottom pt-3 reward-border" />
            <br />
            <div className="">
              <div className="question-item">
                {`${
                  rewardPromptQuestionSplit && rewardPromptQuestionSplit[1]
                    ? rewardPromptQuestionSplit[1]
                    : ''
                }`}
              </div>
              <div>
                <div className={`pt-3 ${isFlex ? 'd-flex' : ''} `}>
                  {questionsData &&
                    questionsData[indexQuestion]?.answer.map(val => (
                      <div key={val.id} className={isFlex ? '' : 'pb-2'}>
                        <CheckGroup
                          label={capitalizeFirstLetter(val?.value) || ''}
                          checked={idSelected === val.id}
                          onChange={() => {
                            setIdSelect(val.id);
                            if (capitalizeFirstLetter(val?.value) === 'Yes') {
                              loggerHelper(
                                logType.clicked_interstetial_card_radiobutton_yes,
                              );
                            }
                            if (capitalizeFirstLetter(val?.value) === 'No') {
                              loggerHelper(
                                logType.clicked_interstetial_card_radiobutton_no,
                              );
                            }
                          }}
                          wrapperClass="p-icon p-default p-round p-pulse p-tada"
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="continue-prompt">
            <div className="text-center pt-2 pr-2">
              <button
                disabled={!idSelected}
                type="button"
                onClick={onAnswerPrompt}
                className="btn btn-base btn-continue-prompt"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <button onClick={onSkipUPrompt} type="button" className="btn-skip">
            Skip
          </button>
        </div>
      </ModalItems>
    </div>
  );
}
