import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Footer, HomeNavbar } from '../../components';
import RouteNames from '../../app/RouteNames';
import MenuProfile from './MenuPage';
import LinkedAccount from './LinkedAccount';
import ProfileInfo from './ProfileInfo';
import UpdatedProfile from './ProfileUpdate';
import PrivacySecurity from './PrivacySecurity';
import Help from './Help';
import CustomerCare from './CustomerCare';
// import { TermLoad } from './Term/LoadTerm';
import TermLoad from './Term';
import ContactUs from './ContactUs';
import DeleteAccount from './DeleteAccount';
import { DeleteAccountProvider } from './DeleteAccount/DeleteAccountProvider';

export default function MyProfile(props) {
  return (
    <div className="container-page my-profile-wrapper" aria-hidden="true">
      <HomeNavbar history={props.history} />
      <div
        className={`col-md-12 p-0 m-auto reward-container reward-grp-page pt-5 full-height`}
        id="my-profile-container"
      >
        <Switch>
          <Route
            exact
            path={RouteNames.myProfile.path}
            component={MenuProfile}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.linkedAccount}
            component={LinkedAccount}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.profile}
            component={ProfileInfo}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.updatedProfile}
            component={UpdatedProfile}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.privacySecurity}
            component={PrivacySecurity}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.help}
            component={Help}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.customerCare}
            component={CustomerCare}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.acknowledgement}
            component={() => (
              <TermLoad
                title="Disclaimers"
                id={process.env.REACT_APP_TERMS_DISCLAIMER_ID}
              />
            )}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.termOfUse}
            component={() => (
              <TermLoad
                title="Terms and Conditions"
                id={process.env.REACT_APP_TERMS_CONDITIONS_WEB_ID}
              />
            )}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.privacy}
            component={() => (
              <TermLoad
                title="Privacy"
                id={process.env.REACT_APP_TERMS_PRIVACY_POLICY_ID}
              />
            )}
          />
          <Route
            exact
            path={RouteNames.myProfile.subPath.contact}
            component={ContactUs}
          />
          <DeleteAccountProvider>
            <Route
              exact
              path={RouteNames.myProfile.subPath.deleteAccount}
              component={DeleteAccount}
            />
          </DeleteAccountProvider>

          <Route
            exact
            path={RouteNames.myProfile.path}
            render={props => <Redirect to={RouteNames.myProfile.path} />}
          />
        </Switch>
        <div className="full-height custom-body">
          <Footer wrapperClass="" />
        </div>
      </div>
    </div>
  );
}
