import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export const VerticalSlide = ({ children }) => {
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      emulateTouch
      infiniteLoop
      autoPlay={false}
      verticalSwipe="natural"
      axis="vertical"
    >
      {children}
    </Carousel>
  );
};
