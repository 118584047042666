import React from 'react';
import { ModalBase } from './ModalBase';
import { iconRelinkSuccess } from 'containers/Assets/images';

export const ModalConfirmRelinkSuccess = props => {
  const { toggle, isOpen, card } = props;

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      className="modal-confirm-card-link"
    >
      <div className="modal-confirm-card-link__content d-flex flex-column justify-content-between">
        <div className="">
          <div className="modal-confirm-card-link__title">
            Relinking your account successful.
          </div>
          <div className="modal-confirm-card-link__message">
            The following account has been successfully Relinked.
          </div>
          <div className="modal-confirm-card-link__card">
            <div className="d-flex justify-content-between align-items-center">
              <div className="card-inner d-flex justify-content-between align-items-center">
                <div className="card-img">
                  {card?.cardImage ? (
                    <img src={card?.cardImage || null} alt="card-avatar" />
                  ) : (
                    <div className="card-img border" />
                  )}
                </div>
                <span className="card-name">
                  {card?.cardShortName || 'null'}
                  {' - '}
                  {card?.accountMask || '123'}
                </span>
              </div>
              <img
                src={iconRelinkSuccess}
                alt="icon-checked"
                className="card-icon"
              />
            </div>
          </div>
        </div>
        <div className="modal-confirm-card-link__btn" onClick={toggle}>
          Confirm
        </div>
      </div>
    </ModalBase>
  );
};
