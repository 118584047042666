import * as React from 'react';
import { creditCard } from 'containers/Assets/images';

interface propsCard {
  isNotCalc?: boolean;
}

export function NoCardContent({ isNotCalc }: propsCard) {
  return (
    <div className="col-md-12 no-stats-grp">
      <img
        src={creditCard}
        alt=""
        className="image-no-stats"
        width="102px"
        height="64px"
      />
      <div className="title-no-stats">
        {isNotCalc
          ? 'We are having trouble in pulling transactions for this card.'
          : `Oops! You don’t have any stats for this month.`}
      </div>
    </div>
  );
}
