import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export const CarouselGroupCenterMode = ({
  children,
  selectedItem,
  percent,
  infiniteLoop,
  ...rest
}: any) => {
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={infiniteLoop || false}
      centerMode
      autoPlay={false}
      axis="horizontal"
      selectedItem={selectedItem}
      showIndicators={false}
      emulateTouch={false}
      centerSlidePercentage={percent || 33.33}
      {...rest}
    >
      {children}
    </Carousel>
  );
};
