import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import RouteNames from './RouteNames';
import Layout from 'containers/Layouts';
import LandingPage from 'containers/LandingPage/Loadable';
import { TermlyPage } from 'containers/TermlyPage/Loadable';
import HomePage from 'containers/HomePage/Loadable';
import ReferralPage from 'containers/ReferralPage/Loadable';
import PlaidOauth from 'containers/PlaidOauth';
import MyProfile from 'containers/MyProfile';
import NearLocation from 'containers/HomePage/EstimateReward/NearLocation';

const wrappedRoutes = () => (
  <Layout>
    <Switch>
      <Route
        exact
        path={RouteNames.plaidCallback.path}
        component={PlaidOauth}
      />
      {/* <Route exact path={RouteNames.referral.path} component={ReferralPage} /> */}
      <Route
        exact
        path={RouteNames.referral.path}
        component={props => <ReferralPage {...props} isStarbucks={true} />}
      />
      <Route path={RouteNames.myProfile.path} component={MyProfile} />
      <Route
        path={RouteNames.estimateReward.subPath.nearLocation}
        exact
        component={NearLocation}
      />
      <Route path={RouteNames.homePage.path} component={HomePage} />
    </Switch>
  </Layout>
);

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={RouteNames.landing.path} component={LandingPage} />
      <Route exact path={RouteNames.journey.path} component={LandingPage} />
      <Route
        exact
        path={RouteNames.termsConditionsWeb.path}
        component={() => (
          <TermlyPage id={process.env.REACT_APP_TERMS_CONDITIONS_WEB_ID} />
        )}
      />
      <Route
        exact
        path={RouteNames.termsConditionsOS.path}
        component={() => (
          <TermlyPage id={process.env.REACT_APP_TERMS_CONDITIONS_OS_ID} />
        )}
      />
      <Route
        exact
        path={RouteNames.privacyPolicy.path}
        component={() => (
          <TermlyPage
            id={process.env.REACT_APP_TERMS_PRIVACY_POLICY_ID}
            type="privacy"
          />
        )}
      />
      <Route
        exact
        path={RouteNames.disclaimer.path}
        component={() => (
          <TermlyPage id={process.env.REACT_APP_TERMS_DISCLAIMER_ID} />
        )}
      />
      <PrivateRoute path="" component={wrappedRoutes} />
    </Switch>
  </BrowserRouter>
);

export default Router;
