import React from 'react';
import { useHistory } from 'react-router-dom';
import { previousIco } from 'containers/Assets/images';
import FormSubmitText from './FormSubmitText';
import AskConfirm from './AskConfirm';
import ValidateOTP from './ValidateOTP';
import Finish from './Finish';
import { useDeleteAccount } from './DeleteAccountProvider';

export default function MenuProfile() {
  const history = useHistory();
  const { pageType, setPageType } = useDeleteAccount();

  return (
    <div className="profile-link-account-wrapper contact-us-wrapper delete-account-wrapper">
      <div className="text-center header-back-grp">
        <button
          type="button"
          className={`"btn btn-select float-left pl-0`}
          onClick={() => {
            pageType === 1 ? history.goBack() : setPageType(pageType - 1);
          }}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text cursor">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">Delete Account</span>

        <div className="container-contact row ml-0 mr-0">
          {pageType === 1 && <FormSubmitText />}
          {pageType === 2 && <AskConfirm />}
          {pageType === 3 && <ValidateOTP />}
          {pageType === 4 && <Finish />}
        </div>
      </div>
    </div>
  );
}
