import { prettifyDoaminPath, pathNames } from '../path';
import axios from 'axios';

export const getRewardSummary = params =>
  axios.get(`${prettifyDoaminPath(pathNames.rewardSummary)}`, { params });

export const getRewardEarned = params =>
  axios.get(`${prettifyDoaminPath(pathNames.rewardEarned)}`, { params });

export const getRewardEarnedById = ({ id, ...params }) =>
  axios.get(`${prettifyDoaminPath(pathNames.rewardEarnedById)}/${id}`, {
    params,
  });

export const addSpecialBonus = ({ id, ...params }) =>
  axios.put(
    `${prettifyDoaminPath(pathNames.specicalBonus)}/${id}/special-offer`,
    params,
  );

export const getRewardMissed = params =>
  axios.get(`${prettifyDoaminPath(pathNames.rewardMissed)}`, { params });

export const getRewardMissedDetails = params =>
  axios.get(`${prettifyDoaminPath(pathNames.rewardMissedDetails)}`, { params });

export const getRewardPrompt = () =>
  axios.get(`${prettifyDoaminPath(pathNames.prompt)}`);

export const answerRewardPrompt = ({ id, ...params }) =>
  axios.put(`${prettifyDoaminPath(pathNames.promptAnswer, id)}`, params);

export const skipRewardPrompt = ({ id, ...params }) =>
  axios.put(`${prettifyDoaminPath(pathNames.promptSkip, id)}`, params);

export const getRecommended = params => {
  return axios.get(`${prettifyDoaminPath(pathNames.getRecommended)}`, {
    params,
  });
};

export const getRecommendedDetails = params => {
  return axios.get(`${prettifyDoaminPath(pathNames.getRecommendedDetails)}`, {
    params,
  });
};

export const getRecommendedSuggestedDetails = params => {
  return axios.get(
    `${prettifyDoaminPath(pathNames.getRecommendedSuggestedDetails)}`,
    {
      params,
    },
  );
};

export const getRecommendedCustomizesDetails = () => {
  return axios.get(
    `${prettifyDoaminPath(pathNames.getRecommendedCustomizesDetails)}`,
  );
};

export const updateCustomizes = ({ ...params }) =>
  axios.post(
    `${prettifyDoaminPath(pathNames.getRecommendedCustomizesDetails)}`,
    params,
  );

export const getReferralData = () =>
  axios.get(`${prettifyDoaminPath(pathNames.myReferral)}`);

export const getMerchantsData = () =>
  axios.get(`${prettifyDoaminPath(pathNames.getMerchants)}`);

export const selectMerchant = params =>
  axios.get(`${prettifyDoaminPath(pathNames.selectMerchant)}`, { params });

export const searchMerchants = params =>
  axios.get(`${prettifyDoaminPath(pathNames.searchMerchants)}`, { params });

export const getCardMappingFailed = () =>
  axios.get(`${prettifyDoaminPath(pathNames.cardMappingFailed)}`);

export const getCardLinked = params =>
  axios.get(`${prettifyDoaminPath(pathNames.cardLinked)}`, { params });

export const updateCard = params =>
  axios.put(`${prettifyDoaminPath(pathNames.updateCard)}`, params);

export const calculateRewards = () =>
  axios.post(`${prettifyDoaminPath(pathNames.calculateReward)}`);
