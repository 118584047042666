import * as React from 'react';
import { formatMoneyValue } from 'utils/utils';

export function MissedCardDetails(props) {
  const { data } = props;
  return (
    <div className="card-details col-md-12 d-flex">
      <div className="col-md-3 p-0 pr-3 img-card-grp">
        <img
          src={data?.categoryImageUrl}
          alt="img-nav"
          width="40px"
          height="40px"
          className="missed-image-card"
        />
      </div>
      <div className="col-md-9 row rw-card-grp missed-card-img-dt">
        <div className="m-auto row spent-net-container">
          <div className="p-0 spent-item">
            <div className="spent-title">Net Spent</div>
            <div className="spent-price">{`$${
              data?.totalSpend ? formatMoneyValue(data?.totalSpend, true) : 0
            }`}</div>
          </div>
          <div className="border-right" />
          <div className="p-0 rewards-item">
            <div className="rewards-title">Missed</div>
            <div className="rewards-price">{`$${
              data?.totalMissedReward
                ? formatMoneyValue(data?.totalMissedReward, true)
                : 0
            }`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
