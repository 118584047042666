import React from 'react';
import { ModalBase } from './ModalBase';

export const ModalConfirmRelink = props => {
  const { toggle, onSubmit, isOpen, card } = props;

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      className="modal-confirm-card-link"
    >
      <div className="modal-confirm-card-link__content d-flex flex-column justify-content-between">
        <div>
          <div className="modal-confirm-card-link__title">Relink your card</div>
          <div className="modal-confirm-card-link__message">
            Do you want to Relink this account from Uthrive?
          </div>
          <div className="modal-confirm-card-link__card">
            <div className="left-card d-flex align-items-center">
              <div className="card-img">
                {card?.cardImage ? (
                  <img src={card?.cardImage || null} alt="card-avatar" />
                ) : (
                  <div className="card-img-border" />
                )}
              </div>
              <span className="card-name">
                {card?.cardShortName || 'null'}
                {' - '}
                {card?.accountMask || ''}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="modal-confirm-card-link__btn" onClick={onSubmit}>
            Confirm
          </div>
          <div className="modal-confirm-card-link__btn-back" onClick={toggle}>
            Back
          </div>
        </div>
      </div>
    </ModalBase>
  );
};
