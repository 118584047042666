import React from 'react';

export default function InputCustom({ renderLabel, label, ...rest }: any) {
  return (
    <div className={'input-custom-wrapper'}>
      {renderLabel ? (
        renderLabel()
      ) : (
        <label className="input-label">{label}</label>
      )}
      <input className="input-content" {...rest} />
    </div>
  );
}
