import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { fieldValidation } from 'containers/Assets/images';

const OTPGroup = ({
  isReset,
  isValid,
  label,
  type,
  id,
  elm,
  wrapperClass,
  onChange,
  name,
  validText,
  isShowImgValide,
  ...rest
}: any) => {
  const idRandom = id || `${Math.random().toString().replace('.', '')}`;
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (isReset) {
      setOtp('');
    }
  }, [isReset]);

  const handleChange = otp => {
    setOtp(otp);
    onChange({ name, value: otp });
  };

  return (
    <div className={`form-group otp-form p-0 m-0 ${wrapperClass}`}>
      <OtpInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="US"
        name={name}
        value={otp}
        autocomplete="one-time-code"
        onChange={handleChange}
        className="form-control otp-group"
        numInputs={6}
        isInputNum
        {...rest}
      />
      {isShowImgValide && (
        <img
          src={fieldValidation}
          className="validation-img"
          alt="img-validation"
          height="30px"
          width="30px"
        />
      )}
      {label && (
        <label className="small-font-size" htmlFor={idRandom}>
          {label || ''}
        </label>
      )}
      {isValid && (
        <div className="small-font-size valid-text">{validText || ''}</div>
      )}
      {elm ? elm : ''}
    </div>
  );
};

export default OTPGroup;
