import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCardsMappingFailed,
  selectCardsLinked,
} from '../HomePage/slice/selectors';
import { useHomeFormSlice } from '../HomePage/slice';
import {
  ListValidationCardLinkedAccount,
  ListValidationCard,
  SelectYourCard,
  HaveSameCard,
  PendingCard,
  EnterNameCard,
  EnterNameCardEditAgain,
  AfterEnterNameCard,
} from 'components/CardValidation';
import { LoaderPlaid } from '../../components/common';
import './styles.scss';

export default function CardValidation({
  step,
  ready,
  isEarned,
  isLinked,
  cardValidateLinked,
  notValidated,
  onOpenPlaid,
  handleGoToStep,
  handleGetRewardSummary,
  handleGetLinkedAccount,
  handleGoToCardValidation,
}) {
  const dispatch = useDispatch();
  const { actions } = useHomeFormSlice();
  const cardsMappingFailed = useSelector(selectCardsMappingFailed);
  const cardsLinked = useSelector(selectCardsLinked);

  const [cardValidate, setCardValidate] = useState<any>(null);
  const [cardsValidated, setCardsValidated] = useState<any>([]);
  const [cardNameEdit, setCardNameEdit] = useState<any>('');
  const [isLoading, setLoading] = useState<any>(false);

  useEffect(() => {
    if (!cardValidate && cardValidateLinked) {
      setCardValidate(cardValidateLinked);
    }
    if (step === 2) {
      handleGoToStep(2);
    }
  }, [cardValidateLinked, step]);

  useEffect(() => {
    const selectCard = document.getElementById('my-profile-container');
    if (selectCard) selectCard.scrollTop = 0;
  });

  const handleGetListCardsSelect = (cardFailed, cardStatus) => {
    if (!cardFailed || !cardStatus) return;
    else {
      setCardValidate(cardFailed);
      switch (cardStatus) {
        case 'NOT_VALIDATED':
          dispatch(
            actions.getCardsLinked({
              params: { accountId: cardFailed?.accountId },
              cb: () => {
                handleGoToStep(2);
                if (isLinked) {
                  handleGoToCardValidation(cardFailed, cardStatus);
                }
              },
            }),
          );
          break;
        case 'PENDING':
          dispatch(
            actions.getCardsLinked({
              params: { accountId: cardFailed?.accountId },
              cb: data => {
                handleGoToStep(3);
                if (isLinked) {
                  handleGoToCardValidation(cardFailed, cardStatus);
                }
              },
            }),
          );
          break;
        default:
          dispatch(
            actions.getCardsLinked({
              params: { accountId: cardFailed?.accountId },
              cb: () => {
                handleGoToStep(2);
                if (isLinked) {
                  handleGoToCardValidation(cardFailed, cardStatus);
                }
              },
            }),
          );
      }
    }
  };

  const hanldeConfirm = ({ mappedCardId, otherCard, isConfirm, cb }) => {
    let params: Object = {
      accountId: cardValidate?.accountId,
      isConfirm,
    };
    if (otherCard) {
      params = {
        ...params,
        otherCard,
      };
      setCardNameEdit(otherCard);
    }
    if (mappedCardId) {
      params = {
        ...params,
        mappedCardId,
      };
    }
    dispatch(
      actions.updateCardsMappingFailed({
        params,
        cb: data => {
          if (data?.isExistAccount) {
            setCardsValidated(data?.existLinkedAccounts || []);
            handleGoToStep(7);
          } else handleGoToStep(1);
          if (cb) cb();
          if (isLinked) {
            handleGetLinkedAccount();
          }
          if (isEarned) {
            handleGetRewardSummary();
          }
        },
      }),
    );
  };

  const hanldeConfirmY = ({ mappedCardId, cb }) => {
    let params: Object = {
      accountId: cardValidate?.accountId,
      isConfirm: 'Y',
    };
    if (cardNameEdit) {
      params = {
        ...params,
        otherCard: cardNameEdit,
      };
    }
    if (mappedCardId) {
      params = {
        ...params,
        mappedCardId,
      };
    }
    dispatch(
      actions.updateCardsMappingFailed({
        params,
        cb: () => {
          handleGoToStep(1);
          setCardNameEdit('');
          setCardsValidated([]);
          if (cb) cb();
        },
      }),
    );
  };

  const hanldeCalculateRewards = () => {
    setLoading(true);
    dispatch(
      actions.calculatedRewardsCardValidation({
        cb: () => {
          handleGoToStep('home');
          setLoading(false);
          handleGetRewardSummary();
        },
      }),
    );
  };

  const renderContent = () => {
    switch (step) {
      case 'linked-accounts':
        return (
          <ListValidationCardLinkedAccount
            listCards={notValidated}
            handleGetListCardsSelect={handleGetListCardsSelect}
          />
        );
      case 1:
        return (
          <ListValidationCard
            listCards={cardsMappingFailed}
            handleGoToStep={handleGoToStep}
            hanldeCalculateRewards={hanldeCalculateRewards}
            handleGetListCardsSelect={handleGetListCardsSelect}
          />
        );
      case 2:
        return (
          <SelectYourCard
            cardsLinked={cardsLinked}
            cardValidate={cardValidate}
            hanldeConfirm={hanldeConfirm}
          />
        );
      case 3:
        return (
          <PendingCard
            cardValidate={cardValidate}
            handleGoToStep={handleGoToStep}
          />
        );
      case 4:
        return (
          <EnterNameCard
            cardValidate={cardValidate}
            hanldeConfirm={hanldeConfirm}
          />
        );
      case 5:
        return (
          <EnterNameCardEditAgain
            cardValidate={cardValidate}
            hanldeConfirm={hanldeConfirm}
          />
        );
      case 6:
        return <AfterEnterNameCard listCards={[]} />;
      case 7:
        return (
          <HaveSameCard
            cardsValidated={cardsValidated}
            cardValidate={cardValidate}
            hanldeConfirm={hanldeConfirmY}
            handleGoToStep={handleGoToStep}
          />
        );
      default:
        return (
          <ListValidationCard
            listCards={cardsMappingFailed}
            handleGoToStep={handleGoToStep}
            hanldeCalculateRewards={hanldeCalculateRewards}
            handleGetListCardsSelect={handleGetListCardsSelect}
          />
        );
    }
  };
  const notGrid = !!(step === 'linked-accounts');
  return (
    <div className={`card-validation-page ${notGrid ? '' : 'row'} ${step}`}>
      <LoaderPlaid
        isLoading={isLoading}
        onOpenPlaid={onOpenPlaid}
        ready={ready}
      />
      <div
        className={`${
          notGrid ? '' : 'col-md-6 col-sm-8 col-lg-5 col-xl-4'
        } ml-auto mr-auto p-0 card-validation-container`}
      >
        {renderContent()}
      </div>
    </div>
  );
}
