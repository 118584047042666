import * as React from 'react';
import { isEmpty } from 'lodash';
// import { loggerHelper } from 'utils/utils';
// import { logType } from 'types/logTypes';
import { iconCheckSuccess } from 'containers/Assets/images';

export function HaveSameCard({
  cardValidate,
  cardsValidated,
  hanldeConfirm,
  handleGoToStep,
}) {
  const cardNameValidate = `${cardValidate?.pendingLinkedAccount?.mappedCard} - ${cardValidate?.accountMask}`;
  const cardNameValidateSimple = cardValidate?.pendingLinkedAccount?.mappedCard;
  return (
    <div className="card-validation">
      <div className="card-validation__title">You have the same card.</div>
      <div className="card-validation__message">
        {`There is another ${cardNameValidateSimple} card in your linked accounts.
        Are you sure you have multiple of these cards?`}
      </div>
      <div className="card-error">
        <ul className="card-validation__list">
          <li>
            <div className="card-name">{cardNameValidate}</div>
            <div className="card-image border">
              {cardValidate?.cardImg ? (
                <img
                  src={cardValidate.cardImg}
                  alt="avatar card"
                  width="100%"
                  height="100%"
                />
              ) : null}
            </div>
            <div className="card-btn">Pending</div>
          </li>
        </ul>
        <div className="card-validation__btn" onClick={hanldeConfirm}>
          Confirm
        </div>
        <div
          className="card-validation__btn-text"
          onClick={() => handleGoToStep(1)}
        >
          No, take me back
        </div>
      </div>
      {!isEmpty(cardsValidated) ? (
        <>
          <div className="card-validation__label text-left">
            Your linked card
          </div>
          <ul className="card-validation__list">
            {cardsValidated.map(item => {
              const cardNameValidated = !!item?.mappedCard
                ? `${item.mappedCard} - ${item.accountMask}`
                : item?.linkStatus === 'PENDING'
                ? `${item?.otherCardUserPrompt || ''} - ${item.accountMask}`
                : `${item?.institutionShortName} ${item?.accountSubtype} - ${item?.accountMask}`;
              return (
                <li>
                  <div className="card-name">{cardNameValidated}</div>
                  <div className="card-image border">
                    {item?.cardImg ? (
                      <img
                        src={item.cardImg}
                        alt="avatar card"
                        width="100%"
                        height="100%"
                      />
                    ) : null}
                  </div>
                  <div className="card-btn">
                    <img
                      src={iconCheckSuccess}
                      alt="icon-checked"
                      className="icon-check"
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      ) : null}
    </div>
  );
}
