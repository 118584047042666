import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  timeout: 1000,
});

export function setDefaultHeaders(headers) {
  Object.keys(headers).forEach(key => {
    instance.defaults.headers.common[key] = headers[key];
  });
}

export default instance;
