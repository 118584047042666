import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { previousIco } from 'containers/Assets/images';

export default function TermOfUse(props: any) {
  const history = useHistory();
  const loadGMaps = () => {
    const existingScript = document.getElementById('termly-jssdk');
    const termlyDom = document.querySelector('#termly-embed-nest');
    termlyDom?.setAttribute('name', 'termly-embed');
    termlyDom?.setAttribute('data-id', props.id || '');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `${process.env.REACT_APP_TERMLY_PAGE}`;
      script.id = 'termly-jssdk';
      document.body.appendChild(script);
      script.onload = () => {};
    }
  };
  useEffect(() => {
    loadGMaps();
  }, []);

  return (
    <div className="menu-profile-wrapper acknowledgement-wrapper">
      <div className="text-center header-back-grp">
        <button
          type="button"
          className={`"btn btn-select float-left pl-0`}
          onClick={() => history.goBack()}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">
          {props.title || ''}
        </span>
      </div>
      <div className="container-menu-categories row ml-0 mr-0">
        <div
          className="col-md-9 row content-menu-categories ml-auto mr-auto"
          id="content-body-term-script"
        >
          <div data-type="iframe" className="w-100" id="termly-embed-nest" />
        </div>
      </div>
    </div>
  );
}
