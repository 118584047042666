import { prettifyPath } from 'utils/utils';

const pathNames = {
  register: 'auth/register',
  verifyUser: 'auth/register-verify-data',
  getUserByEmail: 'auth/get-user',
  verifyCognitoUser: 'auth/register/verifyCognito',
  myUserRegister: 'auth/register',
  updateCognitoPhone: 'auth/update-cognito-phone',
  getLinkPlaid: 'plaid/getLink',
  getRelinkPlaid: 'plaid/reLink',
  validatePlaid: 'plaid/validate',
  tokenPlaid: 'plaid/token',
  linkedAccountById: 'linked-account/itemId',
  unlinkAccountPlaid: 'plaid/unlink-account',
  relinkAccountPlaid: 'plaid/relink-account',
  addCardAccountPlaid: 'plaid/add-card',
  plaidByUser: 'user',
  rewardSummary: 'reward/summary',
  rewardEarned: 'reward/earned',
  rewardEarnedById: 'reward/earned',
  specicalBonus: 'welcome-bonus',
  rewardMissedDetails: 'reward/missed-detail',
  rewardMissed: 'reward/missed',
  prompt: 'prompt',
  promptAnswer: 'prompt/:id/answer',
  promptSkip: 'prompt/:id/skip',
  updateAccount: 'user/update-account-verification',
  getRecommended: 'recommended-card/recommended-cards-v2',
  getRecommendedDetails: 'recommended-card/your-cards-detail-v2',
  getRecommendedSuggestedDetails: 'recommended-card/suggested-cards',
  getRecommendedCustomizesDetails: 'recommended-card/customizes',
  myReferral: 'user-referral/my-referral',
  getMerchants: 'estimate-reward/get-merchant',
  selectMerchant: 'estimate-reward/by-merchant',
  searchMerchants: 'estimate-reward/search-merchant',
  linkedAccount: 'linked-account',
  myProfile: 'profile/get-profile',
  updateProfile: 'profile/update-profile',
  notificationPreference: 'notification-preference',
  contactUs: 'contact-us',
  helpQuestion: 'help-question/v2',
  google: {
    subPath: {
      findPlace: 'google-map/find-place',
      nearBy: 'google-map/nearby',
      textSearch: 'google-map/text-search',
      getCalcByPlace: 'estimate-reward/by-place',
    },
  },
  mappedCardFailed: 'user/mapped-card-failed',
  alertLambda: 'slack-alert/lambda',
  alertLog: 'slack-alert/alert',
  deleteAccount: 'user/delete-account',
  getOTP: 'user/send-otp',
  referralTracking: 'user-referral/tracking',
  cardMappingFailed: 'linked-account/mapping-failed',
  cardLinked: 'linked-account/card',
  updateCard: 'linked-account/update-card',
  calculateReward: 'linked-account/calculate-reward',
  trackingUserClickPersonalizedAdvice: 'user/click-tracking',
};

const prettifyDoaminPath = (name, id = '') => {
  if (id) {
    return prettifyPath(`${process.env.REACT_APP_URL_API}/${name}`, id);
  }
  return `${process.env.REACT_APP_URL_API}/${name}`;
};

export { pathNames, prettifyDoaminPath };
