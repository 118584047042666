/**
 * Asynchronously loads the component for LandingPage
 */

import { lazyLoad } from 'utils/loadable';

const LandingPage = lazyLoad(
  async () => await import('./index'),
  module => module.default,
);

export default LandingPage;
