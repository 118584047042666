import React, { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch } from 'react-redux';
import { useHomeFormSlice } from '../HomePage/slice';
import { useHistory } from 'react-router-dom';

const OAuthLink = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useHomeFormSlice();
  const linkToken = localStorage.getItem('link_token');
  const onSuccess = React.useCallback((public_token: string) => {
    dispatch(actions.validatePlaid({ token: public_token }));
    history.push('/');
  }, []);
  const onExit = (err, metatdata) => {
    if (err) {
      alert(err || 'Validate Plaid OAuth Failed');
    }
    history.push('/');
  };
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    receivedRedirectUri: window.location.href,
    onSuccess,
    onExit,
  };
  // const { open, ready, error } = usePlaidLink(config);
  const { open, ready } = usePlaidLink(config);
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);
  return null;
};
export default OAuthLink;
