import * as React from 'react';

export const LinkMoreAccountsButton = ({ onOpenPlaid, disabled }) => {
  return (
    <div className="col-md-12 text-center pb-3">
      <button
        onClick={onOpenPlaid}
        disabled={disabled}
        type="button"
        className="btn btn-base btn-link-more-account"
      >
        Link More Accounts
      </button>
    </div>
  );
};
