import * as React from 'react';
import { nextIco, nextIcoWhite } from 'containers/Assets/images';

export function NextIconImageButton(props) {
  const { wrapperClass, imgClass, isWhite, ...rest } = props;
  return (
    <div className={wrapperClass || 'next-ico-reward-grp '} {...rest}>
      <button
        type="button"
        className={'btn no-border no-bg pr-0 mobile-btn-padding'}
        onClick={() => {}}
      >
        <img
          src={isWhite ? nextIcoWhite : nextIco}
          alt="img-nav"
          className={imgClass || 'float-right'}
          style={{ width: 30, height: 30 }}
          height="30px"
          width="30px"
        />
      </button>
    </div>
  );
}
