import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './styles.scss';

export const ModalBase = props => {
  const { toggle, isOpen, className, children } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} centered>
      <div className="modal-view">
        <button className="modal-btn-close" onClick={toggle}>
          ×
        </button>
        <ModalBody>{children}</ModalBody>
      </div>
    </Modal>
  );
};
