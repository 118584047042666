import * as React from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import moment from 'moment';
import { GenericInput } from '../common';
// import { previousIco, nextIco } from 'containers/Assets/images';

export function MonthYearSelect({
  onSelectMonth,
  isSelectedMonth,
  wrapperClass,
  onPreviousMonth,
  setMonthSelect,
  monthSelect,
  onNextMonth,
  mainClassName,
  yearTitle = '',
  monthTitle = '',
  isLinked,
}) {
  const monthSelected = moment(monthSelect || new Date());
  const currentMonth = moment(new Date()).subtract(1, 'months');
  const yearMontAgo = moment(new Date()).subtract(12, 'months');
  const isEnnableNextMonth = currentMonth.isAfter(monthSelected);
  const isEnnablePreviousMonth = monthSelected.isAfter(yearMontAgo);

  const validMonth = current => {
    const yearMoreThan = moment().subtract(12, 'months');
    return current.isAfter(yearMoreThan);
  };

  return (
    <div className={mainClassName || ''}>
      <div className={`text-center select-group ${wrapperClass || ''}`}>
        <button
          type="button"
          id="popver-prevent"
          className={`"btn btn-select border-radius-right btn-month ${
            !isSelectedMonth ? 'acitve' : ''
          }`}
          onClick={e =>
            isLinked
              ? onSelectMonth({ isMonth: false })
              : e.currentTarget.focus()
          }
        >
          {yearTitle ? yearTitle : 'Year'}
        </button>
        {!isLinked && (
          <UncontrolledPopover
            placement="top"
            target="popver-prevent"
            className="popver-custom popver-prevent"
            trigger="focus"
          >
            <div className="x-close-popver">X</div>
            <PopoverBody>Please wait, year view will be ready soon</PopoverBody>
          </UncontrolledPopover>
        )}
        <button
          type="button"
          className={`"btn btn-select border-radius-left btn-year ${
            isSelectedMonth ? 'acitve' : ''
          }`}
          onClick={() => onSelectMonth({ isMonth: true })}
        >
          {monthTitle ? monthTitle : 'Month'}
        </button>
      </div>
      <div className="col-md-12 month-select">
        {isSelectedMonth && (
          <div className="month-select-group">
            <button
              className="no-btn"
              onClick={onPreviousMonth}
              disabled={!isEnnablePreviousMonth}
            >
              <i
                className={`arrow left-ico ${
                  !isEnnablePreviousMonth ? 'disable-arrow' : ''
                }`}
              />
            </button>
            <GenericInput
              wrapperClass="m-auto pb-2 pt-2 text-center month-input"
              onChange={({ value }) => {
                setMonthSelect(value);
                onSelectMonth({ isMonth: true, month: value });
              }}
              value={monthSelect || moment(new Date())}
              initialValue={monthSelect || new Date()}
              dateFormat="MMM, YY"
              type="date"
              name="birth_date"
              isValidDate={validMonth}
              inputProps={{
                className: 'date-time-select-reward',
                readOnly: true,
                disabled: true,
              }}
              validText="Invalid DOB. Try again."
            />
            <button
              className="no-btn"
              onClick={onNextMonth}
              disabled={!isEnnableNextMonth}
            >
              <i
                className={`arrow right-ico ${
                  !isEnnableNextMonth ? 'disable-arrow' : ''
                }`}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
