import React from 'react';
import { fieldValidation } from 'containers/Assets/images';
import NumberFormat from 'react-number-format';
import { loggerHelper } from 'utils/utils';

const PhoneGroup = ({
  isValid,
  label,
  type,
  id,
  elm,
  wrapperClass,
  onChange,
  name,
  validText,
  isShowImgValide,
  onBlur,
  logger,
  ...rest
}: any) => {
  const idRandom = id || `${Math.random().toString().replace('.', '')}`;
  return (
    <div className={`form-group p-0 m-0 ${wrapperClass}`}>
      <NumberFormat
        onChange={evt => onChange({ name, value: evt.target.value })}
        {...rest}
        className="number-input"
        format="###-###-####"
        onBlur={() => {
          if (onBlur) onBlur();
          if (logger) loggerHelper(logger);
        }}
        mask="_"
      />
      {isShowImgValide && (
        <img
          src={fieldValidation}
          className="validation-img phone-validation"
          alt="img-validation"
          height="30px"
          width="30px"
        />
      )}
      {label && (
        <label className="small-font-size" htmlFor={idRandom}>
          {label || ''}
        </label>
      )}
      {isValid && (
        <div className="small-font-size valid-text">{validText || ''}</div>
      )}
      {elm ? elm : ''}
    </div>
  );
};

export default PhoneGroup;
