import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import numeral from 'numeral';

export const ProgressBarStep = ({ lastValue, currentValue, firstValue }) => {
  const isExitCurrentValue = Number.parseInt(currentValue, 10) > 0;
  const listStep = [
    {
      value: firstValue,
      wrapClass: 'range-step first-step',
    },
  ];
  // if (isExitCurrentValue) {
  //   listStep.push({
  //     value: `${numeral(currentValue || '0').format('0,0.00') || '0'}`,
  //     wrapClass: '',
  //   });
  // }
  if (lastValue) {
    listStep.push({
      value: lastValue,
      wrapClass: 'range-step last-step',
    });
  }
  return (
    <div>
      {isExitCurrentValue && (
        <span
          style={{
            position: 'absolute',
            left: `${(currentValue / lastValue) * 100}%`,
            top: '-10px',
          }}
        >
          <span className="arrow-up" />
          <span className="current-value-progress">
            {`${numeral(currentValue || '0').format('0,0.00') || '0'}`}
          </span>
        </span>
      )}
      <ProgressBar
        percent={isExitCurrentValue ? (currentValue / lastValue) * 100 : 0}
        filledBackground="linear-gradient(90deg, #4B64FF 0%, #3C4DB6 100%)"
      >
        {listStep.map(val => (
          <Step transition="scale">
            {({ accomplished }) => (
              <span className={val.wrapClass || ''}>${val.value || '0'}</span>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
};
