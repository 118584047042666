import { prettifyDoaminPath, pathNames } from '../path';
import axios from 'axios';

export const getLinkPlaid = () =>
  axios.get(`${prettifyDoaminPath(pathNames.getLinkPlaid)}`);

export const validatePlaid = ({ payload }) => {
  return axios.post(`${prettifyDoaminPath(pathNames.validatePlaid)}`, payload);
};

export const getPlaidByUser = () =>
  axios.get(`${prettifyDoaminPath(pathNames.plaidByUser)}`);

export const updateAccountUser = ({ ...params }) =>
  axios.put(`${prettifyDoaminPath(pathNames.updateAccount)}`, params);

export const getReLinkPlaid = ({ id, ...rest }) =>
  axios.get(`${prettifyDoaminPath(pathNames.getRelinkPlaid)}/${id}`, {
    params: rest,
  });

export const updateRelink = ({ id, ...params }) =>
  axios.post(
    `${prettifyDoaminPath(pathNames.getRelinkPlaid)}/${id}/success`,
    params,
  );

export const alertLambda = params =>
  fetch(prettifyDoaminPath(pathNames.alertLambda), {
    method: 'POST',
    headers: {
      Authorization: 'Basic ' + btoa(process.env.REACT_APP_BTOA_LAMBDA || ''),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then(response => response.json())
    .then(json => console.log(json))
    .catch(err => console.log(err));

export const alertLog = params =>
  axios.post(`${prettifyDoaminPath(pathNames.alertLog)}`, {
    ...params,
    platform: 'web',
  });

export const getTrackingReferral = () =>
  axios.get(prettifyDoaminPath(pathNames.referralTracking));

export const getTrackingUserClickPersonalizedAdvice = params =>
  axios.post(
    prettifyDoaminPath(pathNames.trackingUserClickPersonalizedAdvice),
    params,
  );
