import { prettifyDoaminPath, pathNames } from '../path';
import axios from 'axios';

export const findPlaceByGoogle = params =>
  axios.get(`${prettifyDoaminPath(pathNames.google.subPath.findPlace)}`, {
    params,
  });

export const getNearByGoogle = params =>
  axios.get(`${prettifyDoaminPath(pathNames.google.subPath.nearBy)}`, {
    params,
  });

export const searchTextByGoogle = params =>
  axios.get(`${prettifyDoaminPath(pathNames.google.subPath.textSearch)}`, {
    params,
  });

export const selectGooglePlace = params =>
  axios.get(`${prettifyDoaminPath(pathNames.google.subPath.getCalcByPlace)}`, {
    params,
  });
