import * as React from 'react';
import {
  // loggerHelper,
  getStatusCard,
} from 'utils/utils';
import { upperFirst } from 'lodash';
// import { logType } from 'types/logTypes';
import { iconCheckSuccess } from 'containers/Assets/images';

export function ListValidationCardLinkedAccount({
  listCards,
  handleGetListCardsSelect,
}) {
  return (
    <div className="card-validation">
      <div className="card-validation__title">Validate Accounts</div>
      <div className="card-validation__message">
        Validate these accounts to link successfully and calculate rewards:
      </div>
      <ul className="card-validation__list">
        {listCards?.map(item => {
          const cardName = `${
            item.linkStatus === 'PENDING'
              ? item.otherCardUserPrompt
              : item.institutionName
          }${
            !!item?.accountSubtype
              ? `<br/>${upperFirst(item.accountSubtype)} - ${item.accountMask}`
              : ` - ${item.accountMask}`
          }`;
          const cardImg = item?.cardImage || '';
          const cardStatus = getStatusCard(item?.linkStatus);
          return (
            <li key={cardName}>
              <div className="card-image border">
                {cardImg ? (
                  <img
                    src={cardImg}
                    alt="avatar card"
                    width="100%"
                    height="100%"
                  />
                ) : null}
              </div>
              <div
                className="card-name"
                dangerouslySetInnerHTML={{ __html: cardName }}
              />
              <div
                className="card-btn"
                onClick={() => handleGetListCardsSelect(item, item?.linkStatus)}
              >
                {cardStatus ? (
                  cardStatus
                ) : (
                  <img
                    src={iconCheckSuccess}
                    alt="icon-checked"
                    className="icon-check"
                  />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
