import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.myAccountForm || initialState;

export const selectLinkedAccountData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.linkedAccountData,
);

export const selectPlaidData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.plaidData,
);

export const selectProfileData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.profile,
);

export const selectNotificationsData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.notifications,
);

export const selectHelpQuestionData = createSelector(
  [selectDomain],
  homeFormState => homeFormState.helpQuestion,
);
export const selectPromptQuestion = createSelector(
  [selectDomain],
  homeFormState => homeFormState.promptData,
);

export const selectLinkedAccountById = createSelector(
  [selectDomain],
  homeFormState => homeFormState.linkedAccountById,
);
