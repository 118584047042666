import React, { useState, useEffect } from 'react';
import { useDeleteAccount } from './DeleteAccountProvider';
import { loggerHelper } from 'utils/utils';
import { logType } from 'types/logTypes';

export default function FormSubmitText() {
  const { setPageType, pageType, messages, setMessages } = useDeleteAccount();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    loggerHelper(logType.page_deleteaccount_feedback_load);
    return () => {
      loggerHelper(logType.page_deleteaccount_feedback_exit);
    };
  }, []);
  return (
    <div className="col-md-10 p-0 row ml-auto mr-auto form-submit-text-wrapper">
      <div className="col-md-10 ml-auto mr-auto content-menu-categories">
        <div className="text-thank-you text-left">
          Did you want to delete your Uthrive account?
        </div>
        <div className="box-text-top ">
          We are sorry to see you go!{' '}
          <p>Please share the reason you would like to delete your account:</p>
        </div>
        <div className="text-message">Feedback</div>
        <div className="container-text">
          <textarea
            className="text-contact"
            maxLength={400}
            value={messages}
            onChange={event => setMessages(event.target.value)}
          />
          <p className="text-count">{`${messages?.length}/4000 characters`}</p>
        </div>
        <div className="d-flex justify-content-center box-warning">
          <span className="box-warning__text box-warning__des ml-0 ">
            Your account includes personal data you have shared with Uthrive.
            Once your account is deleted, we won’t be able to get it back for
            you.
          </span>
        </div>
        <div className="d-flex justify-content-center box-warning mt-3">
          <input
            checked={checked}
            onChange={event => setChecked(event.target.checked)}
            type="checkbox"
            className="box-warning__checkbox"
          />
          <span className="box-warning__text">
            I understand what will happen after my data is deleted, I want to
            continue with this request.
          </span>
        </div>
        <div className="d-flex justify-content-center">
          <button
            onClick={() => {
              setPageType(pageType + 1);
              loggerHelper(
                logType.clicked_deleteaccount_feedback_button_continue,
              );
            }}
            disabled={!!!messages?.length || !checked}
            className="bnt-send"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
