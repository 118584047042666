import React, { useEffect } from 'react';
import { useDeleteAccount } from './DeleteAccountProvider';
import { Link } from 'react-router-dom';
import RouteNames from '../../../app/RouteNames';
import { loggerHelper } from 'utils/utils';
import { logType } from 'types/logTypes';
export default function FormSubmitText() {
  const { handleDeleteAccount, loading } = useDeleteAccount();
  useEffect(() => {
    loggerHelper(logType.page_deleteaccount_disablenotification_load);
    return () => {
      loggerHelper(logType.page_deleteaccount_disablenotification_exit);
    };
  }, []);
  return (
    <div className="col-md-10 p-0 row ml-auto mr-auto ask-confirm-wrapper">
      <div className="col-md-10 ml-auto mr-auto content-menu-categories">
        <div className="title-confirm text-center">
          Do you want to disable emails and notifications instead?
        </div>

        <div className="d-flex justify-content-center button-group">
          <Link to={RouteNames.myProfile.subPath.privacySecurity}>
            <button
              onClick={() =>
                loggerHelper(
                  logType.clicked_deleteaccount_disablenotification_button_notificationsetting,
                )
              }
              className="bnt-notification"
            >
              Notification settings
            </button>
          </Link>

          <button
            disabled={loading}
            onClick={() => {
              loggerHelper(
                logType.clicked_deleteaccount_disablenotification_button_continue,
              );
              handleDeleteAccount();
            }}
            className="bnt-continue"
          >
            No, Continue
          </button>
        </div>
      </div>
    </div>
  );
}
