import React from 'react';
import { fieldValidation } from 'containers/Assets/images';
import { loggerHelper } from 'utils/utils';

const InputGroup = ({
  isValid,
  label,
  type,
  id,
  elm,
  wrapperClass,
  onChange,
  validText,
  isShowImgValide,
  rightLabel,
  onBlur,
  logger,
  leftLabel,
  isValidWithRedBorder,
  onClickRightLabel = () => {},
  ...rest
}: any) => {
  const idRandom = id || `${Math.random().toString().replace('.', '')}`;
  return (
    <div className={`form-group p-0 m-0 ${wrapperClass}`}>
      {leftLabel && <span className="left-label">{leftLabel}</span>}
      {type === 'textarea' ? (
        <textarea
          className={`form-control ${isValid ? isValid : ''}`}
          id={idRandom}
          onChange={evt =>
            onChange({ name: evt.target.name, value: evt.target.value })
          }
          rows="3"
          onBlur={() => {
            if (onBlur) onBlur();
            if (logger) loggerHelper(logger);
          }}
          {...rest}
        />
      ) : (
        <input
          type={type || 'text'}
          className={`form-control ${isValid || ''} ${
            isValidWithRedBorder ? 'is-red-bottom-boder' : ''
          }`}
          id={idRandom}
          onChange={evt =>
            onChange({ name: evt.target.name, value: evt.target.value })
          }
          onBlur={() => {
            if (onBlur) onBlur();
            if (logger) loggerHelper(logger);
          }}
          {...rest}
        />
      )}
      {rightLabel && (
        <span className="right-label" onClick={onClickRightLabel}>
          {rightLabel}
        </span>
      )}
      {isShowImgValide && (
        <img
          src={fieldValidation}
          className="validation-img"
          alt="img-validation"
          height="30px"
          width="30px"
        />
      )}
      {label && (
        <label
          className={`small-font-size ${
            isValidWithRedBorder ? 'is-red-color' : ''
          }`}
          htmlFor={idRandom}
        >
          {label || ''}
        </label>
      )}
      {isValid && (
        <div className="small-font-size valid-text">{validText || ''}</div>
      )}
      {elm ? elm : ''}
    </div>
  );
};

export default InputGroup;
