const RouteNames = {
  landing: {
    path: '/landing',
    name: 'landing',
    exact: true,
  },
  landingSignup: {
    path: '/landing?=signup',
    name: 'landingSignup',
    exact: true,
  },
  landingLogin: {
    path: '/landing?=login',
    name: 'landingLogin',
    exact: true,
  },
  homePage: {
    path: '/',
    name: 'homePage',
    exact: true,
  },
  plaidCallback: {
    path: '/plaid-oauth',
    name: 'plaidCallback',
    exact: true,
  },
  termly: {
    path: '/termly',
    name: 'termly',
    exact: true,
  },
  termsConditionsWeb: {
    path: '/termly/desktop',
    name: 'termsConditionsWeb',
    exact: true,
  },
  termsConditionsOS: {
    path: '/termly/os',
    name: 'termsConditionsOS',
    exact: true,
  },

  privacyPolicy: {
    path: '/termly/privacy-policy',
    name: 'privacyPolicy',
    exact: true,
  },

  disclaimer: {
    path: '/termly/disclaimer',
    name: 'disclaimer',
    exact: true,
  },
  referral: {
    path: '/referral',
    name: 'referral',
    exact: true,
  },
  referralStarbucks: {
    path: '/referral-starbucks',
    name: 'referralStarbucks',
    exact: true,
  },
  estimateReward: {
    name: 'estimateReward',
    subPath: {
      nearLocation: '/estimate-reward/near-location',
    },
  },
  myProfile: {
    path: '/my-account',
    name: 'myAccount',
    exact: true,
    subPath: {
      linkedAccount: '/my-account/linked-account',
      help: '/my-account/help',
      refer: '/referral',
      contact: '/my-account/contact',
      profile: '/my-account/profile',
      updatedProfile: '/my-account/update-profile',
      privacySecurity: '/my-account/privacy-security',
      customerCare: '/my-account/customer-care',
      acknowledgement: '/my-account/acknowledgement',
      termOfUse: '/my-account/term-of-use',
      privacy: '/my-account/privacy',
      deleteAccount: '/my-account/delete-my-account',
    },
  },
  journey: {
    path: '/journey',
    name: 'journey',
    exact: true,
  },
};

export default RouteNames;
