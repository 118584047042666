import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export const CarouselGroup = ({ children, selectedItem, ...rest }: any) => {
  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      // infiniteLoop
      autoPlay={false}
      axis="horizontal"
      selectedItem={selectedItem}
      showIndicators={false}
      emulateTouch={false}
      {...rest}
    >
      {children}
    </Carousel>
  );
};
