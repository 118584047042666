import React, { useEffect } from 'react';
import { loggerHelper } from 'utils/utils';
import { logType } from 'types/logTypes';

export default function FormSubmitText() {
  useEffect(() => {
    loggerHelper(logType.page_deleteaccount_submitted_load);
    return () => {
      loggerHelper(logType.page_deleteaccount_submitted_exit);
    };
  });
  useEffect(() => {
    const interval = setInterval(() => {}, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="col-md-10 p-0 row ml-auto mr-auto finish-wrapper">
      <div className="col-md-10 ml-auto mr-auto content-menu-categories">
        <div className="title-finish text-center">
          Your request has been submitted, please check your email for
          confirmation.
        </div>

        <div className="result-description">
          Your request may take up to 30 days. During this time you may not be
          able to access Uthrive, nor can you submit another request.
        </div>
      </div>
    </div>
  );
}
