import React from 'react';
import airbridge from 'airbridge-web-sdk-loader';
import { loggerHelper } from 'utils/utils';

export const CustomButton = props => {
  const {
    text,
    isLoading,
    disabled,
    onClick,
    logger,
    loggerAirbrigde,
    ...rest
  } = props;
  return (
    <button
      type="button"
      className="btn btn-base btn-continue"
      disabled={isLoading || disabled}
      onClick={() => {
        onClick();
        if (logger) loggerHelper(logger);
        if (loggerAirbrigde) {
          airbridge.events.send(loggerAirbrigde.event, {
            label: loggerAirbrigde.event,
            action: loggerAirbrigde.subevent,
          });
        }
      }}
      {...rest}
    >
      {text}
    </button>
  );
};
