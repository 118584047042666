import React from 'react';
import { ModalItems } from '../ModalItems';

export const ModalValidatePlaidFail = props => {
  const { isOpen, toggle, onClickLinkOtherAccount, messageType } = props;
  const getTextRender = () => {
    switch (messageType) {
      case 'ACCOUNTS_LINKED':
        const title = 'Duplicate bank login found';
        const bodyFirst =
          'The bank login you are trying to link is already associated with your Uthrive account';
        const bodySecond =
          'Do you want to simply add other card(s) from the same bank login?';
        const titleButton = 'Add more cards';
        return [title, bodyFirst, bodySecond, titleButton];
      case 'GLOBAL_ACCOUNT_LINKED': {
        const title = 'Card account already exists';
        const bodyFirst =
          'The bank/card you are trying to link already exists with Uthrive.';
        const bodySecond = 'Do you want to link other bank/card?';
        const titleButton = 'Link other accounts';
        return [title, bodyFirst, bodySecond, titleButton];
      }
      default:
        return [];
    }
  };
  return (
    <ModalItems
      isOpen={isOpen}
      className="modal-blur ModalPlaidLinkFail"
      toggle={toggle}
    >
      <div className="ModalPlaidLinkFail-contents">
        <div className="ModalPlaidLinkFail-title">{getTextRender()[0]}</div>
        <div className="ModalPlaidLinkFail-subtTitle">
          <p>{getTextRender()[1]}</p>
          <p>{getTextRender()[2]}</p>
        </div>
        <div className="ModalPlaidLinkFail-btn">
          <button
            onClick={onClickLinkOtherAccount}
            type="button"
            className="btn btn-base btn-link-account"
          >
            {getTextRender()[3]}
          </button>
        </div>
      </div>
    </ModalItems>
  );
};
