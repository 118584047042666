import React, { useState, useRef } from 'react';
import { ModalItems, GenericInput } from '../../components/common';
import { selectGooglePlace } from 'api/google';
import { loggerHelper } from 'utils/utils';
import { logType } from 'types/logTypes';

export function ModalEstimateRewardByPlace({
  isOpenModal,
  toggle,
  mappedMerchant,
  marker,
}) {
  const [totalValue, setTotalValue] = useState('');
  const [topCards, setTopCards] = useState<any>([]);
  const threadRef: any = useRef(null);

  const handleInputAmoutMerchantApi = (spendAmount = null) => {
    setTotalValue(spendAmount || '');
    if (threadRef.current) {
      clearTimeout(threadRef.current);
    }
    if (!spendAmount) {
      setTopCards([]);
      return;
    }
    let params: any = {
      placeName: marker.types[0],
      merchantName: marker.name,
      spendAmount,
    };

    threadRef.current = setTimeout(() => {
      selectGooglePlace(params).then(res => setTopCards(res?.data?.data));
    }, 800);
  };
  return (
    <ModalItems
      toggle={() => {
        toggle();
        setTopCards(null);
        setTotalValue('');
      }}
      className="modal-lg modal-estimate-wrapper"
      modalClass="modal-estimate-container border-radius"
      isOpen={isOpenModal}
      isSupportClose
    >
      <div className="col-md-12 m-auto modal-estimate-content p-0">
        <div className="title-modal">
          {` Enter amount to compare rewards on all your cards at `}
          <u>{mappedMerchant}</u>
        </div>
        <div className="content-body">
          <div className="form-data-container">
            <GenericInput
              wrapperClass="col-md-12 form-item"
              onChange={({ value }) => handleInputAmoutMerchantApi(value)}
              value={totalValue || ''}
              type="number"
              name="totalValue"
              placeholder="$0"
              label="Enter amount"
              style={!totalValue ? { paddingLeft: 0 } : {}}
              leftLabel={totalValue ? '$' : null}
              rightLabel={totalValue ? '⟳' : null}
              onClickRightLabel={() => {
                setTopCards(null);
                setTotalValue('');
              }}
            />
          </div>
          {topCards?.length ? (
            <div className="container-result">
              <div className="title-top-result">Your Top Card</div>
              <div className="list-card-container p-0 row m-0">
                {topCards.map((card, index) => (
                  <div
                    key={index}
                    className="col-12 card-container"
                    onClick={() => {
                      loggerHelper(
                        logType.clicked_viewplacesnearyou_modal_topcard,
                      );
                    }}
                  >
                    <p className="card-name">
                      {card?.cardNameShort || ''}
                      {' - '}
                      {card?.accountMask || ''}
                    </p>
                    <div className="justify-content-between d-flex">
                      <img
                        src={card?.cardImageUrl}
                        alt="card-logo"
                        className="card-logo"
                      />
                      <div className="d-flex">
                        <div className="value-info pl-0 border-right">
                          <p className="label-info mb-0">Spent</p>
                          <p className="mb-0">${totalValue}</p>
                        </div>
                        <div className="value-info pr-0">
                          <p className="label-info mb-0">Rewards</p>
                          <p className="mb-0 font-weight-bold">
                            ${Math.round(card?.rewardAmount || 0)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </ModalItems>
  );
}
