import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { previousIco } from 'containers/Assets/images';
import SwitchCustom from 'components/common/SwitchCustom';
import { useMyAccountFormSlice } from './slice';
import { selectNotificationsData } from './slice/selectors';
import { ModalItems } from 'components/common';

export default function Privacy(props: any) {
  const [isOpenWarning, setOpenWarning] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useMyAccountFormSlice();
  const notificationData = useSelector(selectNotificationsData);

  const handleGetNotification = () => {
    dispatch(actions.getNotification());
  };

  useEffect(() => {
    handleGetNotification();
  }, []);

  const handleUpdateNotification = checkedValue => {
    if (!checkedValue) {
      toggleModalWarning();
      return;
    }
    const params = { isEmailNotificationEnabled: checkedValue };
    dispatch(actions.updateNotification(params));
  };
  const toggleModalWarning = () => {
    setOpenWarning(!isOpenWarning);
  };

  return (
    <div className="privacy-wrapper">
      <div className="text-center header-back-grp">
        <button
          onClick={() => history.goBack()}
          type="button"
          className={`"btn btn-select float-left pl-0`}
        >
          <img
            src={previousIco}
            alt="img-previous"
            className="img-control-back"
          />
          <span className="back-text">Back</span>
        </button>
        <span className="title-selected mr-auto ml-auto">
          Privacy & Security
        </span>
      </div>
      <div className="container-privacy">
        <div className="col-md-10 row  ml-auto mr-auto">
          <div className="col-md-10 ml-auto mr-auto p-0 row content-account-info">
            <div className="box-notification">Notifications</div>
            <div className="box-switch">
              <div className="text-label-box">Email</div>
              <SwitchCustom
                checked={notificationData?.isEmailNotificationEnabled || false}
                onChange={event =>
                  handleUpdateNotification(event.target.checked)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ModalItems
        isOpen={isOpenWarning}
        className="modal-blur modal-warning-wrapper"
        toggle={() => {}}
      >
        <div className="modal-warning-contents">
          <div className="text-warning-exist text-left">
            <p className="font-weight-bold mb-0">Disable Email Notifications</p>
            <p className="mb-0">
              {' '}
              Uthrive will not send email alerts on missing rewards etc., you'll
              still get emails related to your membership.
            </p>
          </div>
          <div className="btn-group">
            <button
              onClick={() => {
                dispatch(
                  actions.updateNotification({
                    isEmailNotificationEnabled: false,
                  }),
                );
                toggleModalWarning();
              }}
              type="button"
              className="btn btn-base "
            >
              OK
            </button>
          </div>
        </div>
      </ModalItems>
    </div>
  );
}
