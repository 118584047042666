import React from 'react';

export function PendingCard({ cardValidate, handleGoToStep }) {
  const cardNameValidate = `${
    cardValidate?.institutionShortName || cardValidate?.cardShortName || ''
  } ${cardValidate?.accountSubtype} - ${cardValidate?.accountMask}`;
  return (
    <div className="card-validation">
      <div className="card-validation__title">
        {`${cardNameValidate} is pending for validation`}
      </div>
      <div className="card-validation__message" style={{ marginBottom: 190 }}>
        <p>
          Your account is being validated by Uthrive. There is no action needed
          from you.
        </p>
        Do you want to edit your selection?
      </div>
      <div className="card-validation__btn" onClick={() => handleGoToStep(2)}>
        Edit
      </div>
    </div>
  );
}
